<template>
  <div class="page">
    <div class="banner-top"></div>
    <div class="news-container main-width-container clearfix">
      <div class="title title-dszx"></div>
      <div class="menu">
        <div class="menu-item"><router-link to="/news/wjtz">文件通知</router-link></div>
        <div class="menu-item"><router-link to="/news/hdbd">活动报道</router-link></div>
        <div class="menu-item"><router-link to="/news/mdgs">名单公示</router-link></div>
        <div class="menu-item"><router-link to="/news/hdyx">活动影像</router-link></div>
        <div class="menu-item"><router-link to="/news/wjzp">往届照片</router-link></div>
        <!-- <div class="menu-item"><router-link to="/news/wjsp">往届视频</router-link></div> -->
      </div>
      <div class="news-list-container">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data () {
    return {
    }
  },
  components: {
    // DataList
  },
  methods :{

  }
}
</script>


<style scoped lang="less">
.page{
  background-image:url("../../assets/images/bg-common.jpg");
  background-position: center top;
  background-size:cover;
  margin-bottom: -160px;
  padding-bottom:300px;
}
.banner-top{
  height:400px;
  background-image: url("../../assets/images/news/banner-top.jpg");
  background-repeat: no-repeat;
  background-size:1920px 400px;
  background-position: center;
}
.news-container{
  margin-top:50px;
}
.title-dszx{
  display:none;
}
.menu{
  background-image:url("../../assets/images/news/bg-menu.png");
  background-repeat: no-repeat;
  background-size:100% 100%;
  width: 218px;
  height: 402px;
  float:left;
  padding-top:90px;
  .menu-item a{
    display:block;
    text-align:center;
    height:59px;
    color:#fff;
    font-size:var(--font-size-l);
    letter-spacing: 2px;
    line-height:59px;
    &.router-link-active{
      background:url("../../assets/images/news/icon-menu.png");

    }
  }

}
.news-list-container{
  margin-left:306px;
  position: relative;
}

  

@media (max-width: 768px) {
  .page {
    margin-bottom: -3rem;
    padding-bottom: 5.2rem;
    .banner-top{
      height:2.68rem;
      background-size:12.864rem 2.68rem;
      }
      .news-container{
  margin-top:.5rem;
}
.title-dszx{
  display:block;
  background-image:url("../../assets/images/news/title-dszx-m.png");
}
.menu{
  background-image:none;
  overflow-x:auto;
  padding-bottom:.25rem;
  width: 100%;
  height: auto;
  float:none;
  padding-top:0;
  font-size:0;
  white-space: nowrap;
  .menu-item{
    display:inline-block;
    margin:0 .1rem;

  }
  .menu-item a{
    display:block;
    text-align:center;
    height:auto;
    color:#666;
    font-size:var(--font-size-l-m);
    letter-spacing: 2px;
    line-height:.6rem;
    padding-bottom:.1rem;
    position:relative;
    &.router-link-active{
      background:none;
      color:#000;
      
      &::after{
        content:' ';
        position:absolute;
        height:.08rem;
        border-radius:.04rem;
        width:90%;
        left:5%;
        background:var(--main-color-2);
        bottom:0;
      }

    }
  }
}
.news-list-container{
  margin-left:0;

}
/deep/.card-container{
  margin:0 auto;
}
    
  }
}
</style>
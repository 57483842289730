<template>
  <div class="page">
    <div class="banner">
      <swiper :options="swiperOption" class="top-banner-swiper" v-if="bannerSwiperData.length > 0">
        <!-- slides -->
        <swiper-slide v-for="(item, index) in bannerSwiperData" :key="index">
          <a :href="item.url?item.url:null" :target="item.url?'_blank':''">
            <img :src="item.image" />
            <div class="banner-title" v-if="item.title">{{ item.title }}</div>
          </a>
        </swiper-slide>
      </swiper>
           <!-- 左右箭头 -->
        <div class="nav-controller nav-prev"><i class="el-icon-arrow-left"></i></div>
        <div class="nav-controller nav-next"><i class="el-icon-arrow-right"></i></div>
      <div class="banner-progress"></div>
    </div>
    <div class="content">
      <div class="page-block block-dsdt">
        <div class="main-width-container">
          <div class="title title-dsdt"></div>
          <TabComponent>
            <template v-slot:tab-header>
              <div tab-id="1" class="active">文件通知</div>
              <div tab-id="2" >活动报道</div>
              <div tab-id="3">名单公示</div>
            </template>
            <template v-slot:tab-content>
              <div tab-id="1" class="active">
                <DataList
                  :dataList="fileListData"
                  showType="TextList"
                  :totalCount="6"
                  :hasPagination="false"
                  linkPattern="/home/article/{id}"
                />
                <router-link to="/news/wjtz" class="more">查看更多></router-link>
              </div>

              <div tab-id="2" >
                <SwiperList
                  class="info-container news-container"
                  :swiperList="activitySwiperList"
                  :swiperPaginationChange="newsSwiperPagination"
                  :infoList="activityList"
                  :paginationColor="paginationColor"
                  moreLink="/news/hdbd"
                ></SwiperList>
              </div>
              <div tab-id="3">
                <DataList
                  :dataList="nameList"
                  showType="TextList"
                  :totalCount="6"
                  :hasPagination="false"
                  linkPattern="/home/article/{id}"
                />
                <router-link to="/news/mdgs" class="more">查看更多></router-link>
              </div>
            </template>
          </TabComponent>
        </div>
      </div>
      <div class="page-block block-hdly-dscg">
        <div class="title title-hdly"></div>
        <DataList
          :dataList="activityPhotoList"
          showType="CardList"
          :totalCount="8"
          :hasPagination="false"
          contentType="video"
          linkPattern="/home/video/{id}"
        />
        <div class="main-width-container">
          <router-link to="/news/hdyx" class="more">查看更多></router-link>
        </div>

        <div class="title title-dscg"></div>
             
        <TabComponent>
          <template v-slot:tab-header>
            <div tab-id="1"  class="active">优秀获奖学生</div>
            <div tab-id="2">优秀科技辅导员</div>
            <div tab-id="3">科技创新优秀学校</div>
          </template>
           
          <template v-slot:tab-content>
           
            <div tab-id="1" class="active">
              <DataList
                :dataList="studentsList"
                showType="CardList"
                showSubType="CardItemPerson"
                :totalCount="studentsList.length"
                :hasPagination="false"
              />
              <div class="main-width-container" v-if="studentsList.length">
                <router-link to="/achievement" class="more">查看更多></router-link>
              </div>
            </div>

            <div tab-id="2" >
              <DataList
                :dataList="assistantList"
                showType="CardList"
                showSubType="CardItemPerson"
                :totalCount="assistantList.length"
                :hasPagination="false"
              />
              <div class="main-width-container" v-if="assistantList.length">
                <router-link to="/achievement" class="more">查看更多></router-link>
              </div>
            </div>
            <div tab-id="3">
              <DataList
                :dataList="schoolList"
                showType="CardList"
                showSubType="CardItemSchool"
                :totalCount="schoolList.length"
                :hasPagination="false"
              />
              <div class="main-width-container" v-if="schoolList.length">
                <router-link to="/achievement" class="more">查看更多></router-link>
              </div>
            </div>
          </template>
        </TabComponent>
      </div>
    </div>
  </div>
</template>

<script>
// 测试数据引入
import { photoSwiperData, newsListData, cardListData, personCardListDataHome, studentCardListDataHome, schoolCardListDataHome } from '../../../public/testData/testDataHome.js'
import SwiperList from '../../components/SwiperList'
import DataList from '../../components/DataList/DataList'
import TabComponent from '../../components/TabComponent.vue'
import { getBannerList, getHomeList, getResultList } from '../../api/home'

export default {
  name: 'homeIndex',
  data () {

    return {
     
      schoolList: [], //学校列表
      assistantList: [], //辅导员列表
      studentsList: [], //学生列表
      activityPhotoList: [], //活动掠影列表
      nameList: [], //名单公示列表
      activitySwiperList: [], //活动轮播列表
      activityList: [], //活动新闻列表
      fileListData: [], //文件通知列表
      status: 1, //文件状态
      bannerSwiperData: [], //顶部轮播列表
      baseUrl: this.$VUE_APP_PUBLIC,
      photoSwiperData, newsListData, cardListData, personCardListDataHome, studentCardListDataHome, schoolCardListDataHome,
      swiperOption: {
        observer: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
          //左右箭头
          navigation: {
          nextEl: '.nav-next',
          prevEl: '.nav-prev'
        },

        loop: true,
        pagination: {
          el: `.banner-progress`,
          clickable: true,

        }
      },
      newsSwiperPagination: 'news-container',
      infoSwiperPagination: 'info-ydkx',
      paginationColor: '#3D7DCD',
    }
  },
  components: {
    SwiperList,
    DataList,
    TabComponent
  },
  created () {
    this.getBannerList()
    this.getFileList()
    this.getActivityList()
    this.getActivitySwiperList()
    this.getNameList()
    this.getActivityPhotoList()
    this.getStudentsList()
    this.getAssistantList()
    this.getSchoolList()
   
  },
  methods: {
  
    // 获取顶部轮播
    async getBannerList () {
      const res = await getBannerList(1)
      this.bannerSwiperData = res.data
    },
    // 获取文件通知列表
    async getFileList () {
      const res = await getHomeList(1, this.status)
      this.fileListData = res.data
    },
    // 获取活动报道新闻列表
    async getActivityList () {
      this.status = 1
      const res = await getHomeList(2, this.status)
      this.activityList = res.data
    },
    // 获取活动报道轮播列表
    async getActivitySwiperList () {
      this.status = 2
      const res = await getHomeList(2, this.status)
      this.activitySwiperList = res.data
      console.log(this.activitySwiperList)
    },
    // 获取名单公示列表
    async getNameList () {
      this.status = 1
      const res = await getHomeList(3, this.status)
      this.nameList = res.data
    },
    // 获取活动影像列表
    async getActivityPhotoList () {
      this.status = 1
      const res = await getHomeList(4, this.status)
      this.activityPhotoList = res.data
    },
    // 获取优秀获奖学生列表
    async getStudentsList () {
      const res = await getResultList(1)
      this.studentsList = res.data
    },
    // 获取辅导员列表
    async getAssistantList () {
      const res = await getResultList(2)
      this.assistantList = res.data
    },
    // 获取学校列表
    async getSchoolList () {
      const res = await getResultList(3)
      this.schoolList = res.data
    }
  }


}
</script>

<style scoped lang="less">
.page{
  .banner{
    position: relative;
      width: 1200px;
     margin: auto;
    .nav-controller{
      position: absolute;
      width: 50px;
      height: 50px;
      margin-top: -38px;
      top: 50%;
      z-index: 3;
      font-size: 30px;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      text-align: center;
      line-height: 50px;
      color: #fff;
      opacity: 0;
      transition: all 0.3s;
      cursor: pointer;
    &.nav-prev{
    left:10px;
    transform:translateX(-10px);
    }
    &.nav-next{
    right:10px;
    transform:translateX(10px);
    }
  }
  &:hover .nav-controller{
    transform:translateX(0);
    opacity:1;
  }
  }

}
.top-banner-swiper {
  max-width: 1200px;
  height: 480px;

  .swiper-slide {
    overflow: hidden;
    img {
      display: block;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: 1200px;
    }
    .banner-title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);
      height: 2.2em;
      line-height: 2.2em;
      font-size: var(--font-size-xl);
      color: #fff;
      text-align: center;
    }
  }
}
.banner-progress {
  text-align: center;
  margin: 15px;
  /deep/.swiper-pagination-bullet {
    margin: 0 10px;
    height: 12px;
    width: 12px;
  }
}
.more {
  display: block;

  text-align: right;
  padding-right: 42px;
  font-size: var(--font-size-m);
  color: #3d7dcd;
  line-height: 20px;
  margin-top: 30px;
}
.page-block {
  border-top: 0.1px solid transparent;
  padding-bottom: 80px;
}
.content {
  margin-top: -56px;
  .title-dsdt {
    background-image: url('../../assets/images/home/title-dsdt.png');
  }
  .title-hdly {
    background-image: url('../../assets/images/home/title-hdly.png');
  }
  .title-dscg {
    background-image: url('../../assets/images/home/title-dscg.png');
  }
  .block-dsdt {
    background-image: url('../../assets/images/home/bg-dsdt.png');
    background-size: cover;
    min-height: 813px;
  }
  .block-hdly-dscg {
    background-image: url('../../assets/images/home/bg-hdly.png');
    background-position: bottom center;
  }
  .news-container {
    margin-top: 80px;
  }
}
.block-dsdt .tab-content .list-container {
  background: #fff;
  padding: 12px 35px 41px 49px;
  border-radius: 8px;
  box-shadow: 0px 14px 54px 0px rgba(165, 181, 192, 0.4);
  margin-top: 44px;

  /deep/li::before {
    background: var(--main-color-3);
  }
}

@media (max-width: 768px) {
  .banner{
    width: auto !important;
    .nav-controller{
        display:none;
      }
  }
  .top-banner-swiper {
    height: 2.68rem;
    .swiper-slide {
      overflow: hidden;
      img {
        display: block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: 100%;
      }
      .banner-title {
        width: 100%;
        height: 2em;
        line-height: 2em;
        font-size: var(--font-size-l-m);
        max-width: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .banner-progress {
    margin: 0.12rem;
    /deep/.swiper-pagination-bullet {
      margin: 0 0.1rem;
      height: 0.2rem;
      width: 0.2rem;
    }
  }
  .page-block {
    border-top: 0.1px solid transparent;
    padding-bottom: 0.5rem;
  }
  .more {
    font-size: var(--font-size-l-m);
    line-height: 0.32rem;
    padding-right: 0;
    margin-top: 0.3rem;
  }
  .content {
    margin-top: 0;

    .title-dsdt {
      margin-top: 0.3rem;
      background-image: url('../../assets/images/home/title-dsdt-m.png');
    }
    .title-hdly {
      background-image: url('../../assets/images/home/title-hdly-m.png');
    }
    .title-dscg {
      background-image: url('../../assets/images/home/title-dscg-m.png');
    }
    .info-ydkx {
      /deep/.banner-swiper {
        order: 0;
      }
    }
    .block-dsdt {
      background: #fff;
      min-height: auto;
    }
    .block-hdly-dscg {
      background-image: linear-gradient(180deg, #dff3ff, #ffffff);
    }

    .news-container {
      margin-top: 0;
    }
  }

  .block-dsdt .tab-content .list-container {
    padding: 0;
    box-shadow: none;
    margin-top: 0;
  }

}

</style>

<template>
  <div class="page">
    <div class="banner-top"></div>
    <div class="block-csxz">
      <div class="title title-csxz"></div>
      <div class="main-width-container">
        <div class="intro-container">
          <!-- <p>
            如果您是一名国内在校中小学生（包括普通中小学、特殊教育学校、中等职业学校等），希望参加全国青少年科技创新大赛，可以阅读<span
              @click="open"
              >《全国青少年科技创新大赛章程》</span
            >和<span @click="openJsgz">《青少年科技创新成果竞赛规则》</span>了解大赛。
          </p> -->
          <p>
            <!-- 全国青少年科技创新大赛不接受个人直接申报，参赛选手需要参加基层赛事，最终由省级青少年科技创新大赛组织结构在本届获奖选手中按分配名额择优推荐。 -->
            全国青少年科技创新大赛不接受个人直接申报，参赛选手需参加基层赛事，全国竞赛参赛者均须通过省级竞赛选拔并按照规定名额择优推荐。如需了解更多信息，请参阅<span
              @click="open"
              >《全国青少年科技创新大赛章程》</span>和<span @click="openJsgz">《青少年科技创新成果竞赛规则》</span>、<span @click="openFdJsgz">《科技辅导员科技教育创新成果竞赛规则》</span>。
          </p>
        </div>

        <div class="block-intro">
          <div class="block-column">
            <div class="photo">
              <img src="../../assets/images/participate/student.jpg" alt="" />
            </div>
            <div class="desc">
              <div class="desc-title">评审专家</div>
              <div class="desc-info">
                关心和支持中国青少年科技创新人才培养的高校、科研院所的科技教育工作者可以申请担任大赛的评审专家。
              </div>
            </div>
          </div>
          <div class="block-column">
            <div class="photo">
              <img src="../../assets/images/participate/zhuanjia.jpg" alt="" />
            </div>
          
            <div class="desc">
              <div class="desc-title">参赛学生</div>
              <div class="desc-info">
                国内在校中小学生，有独立或参与完成的科技创新项目，可以参加基层赛事，经选拔获得参加全国比赛的资格。
              </div>
            </div>
          </div>
          <div class="block-column">
            <div class="photo">
              <img src="../../assets/images/participate/teacher.jpg" alt="" />
            </div>
            <div class="desc">
              <div class="desc-title">志愿者</div>
              <div class="desc-info">
                关心和支持青少年科技创新大赛的社会各界人士可以申请参与志愿服务，和我们一起做好大赛的组织服务工作。
              </div>
            </div>
          </div>
          <div class="block-column">
            <div class="photo">
              <img src="../../assets/images/participate/volunteer.jpg" alt="" />
            </div>
           
            <div class="desc">
              <div class="desc-title">科技辅导员</div>
              <div class="desc-info">
                中小学校科学教师、科技辅导员，各级教育研究机构、校外科技教育机构和活动场所的科技教育工作者，有独立完成的科教制作或科教方案成果，可以参加基层赛事，经选拔获得参加全国比赛的资格。
              </div>
            </div>
          </div>
          <div class="block-column">
            <div class="photo">
              <img src="../../assets/images/participate/company.jpg" alt="" />
            </div>
            <div class="desc">
              <div class="desc-title">支持单位</div>
              <div class="desc-info">
                高校、科研院所、学会、基金会和企业等社会机构可以设立专项奖，支持和鼓励青少年等科技创新活动和在科技教育领域有所突出成绩的教育工作者。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block-jcss">
      <!-- <div class="title title-jcss"></div>
      <div class="main-width-container">
        <Jcss />
      </div> -->
      <div class="title title-sjdw"></div>
      <div class="main-width-container">
        <Sjdw v-if="sponsorList.length > 0" :sponsorData="sponsorList" />
        <router-link to="/participate/sponsor" class="btn-more-sponsor">了解更多</router-link>
      </div>
    </div>
  </div>
</template>

<script>
// import Jcss from './Jcss.vue'
import Sjdw from './Sjdw.vue'
import { getCompany } from '../../api/competition'

export default {

  data () {
    return {
      competitionList: [],
      sponsorList: []
    }
  },
  computed: {


  },
  components: {
    // Jcss, 
    Sjdw
  },
  methods: {
    open () {
      let url = this.$router.resolve('/participate/gz/dszc')
      window.open(url.href)
    },
    openFdJsgz(){
      let url = this.$router.resolve('/participate/gz/kjfd')
      window.open(url.href)
    },
    openJsgz () {
      let url = this.$router.resolve('/participate/gz/jsgz')
      window.open(url.href)
    },

    // 获取设奖单位列表
    async getSponsorList () {
      const res = await getCompany()
      this.sponsorList = res.data
    }
  },
  created () {
    this.getSponsorList()
  }
}
</script>


<style scoped lang="less">
.page {
  .banner-top {
    height: 400px;
    background-image: url('../../assets/images/participate/banner-top.jpg');
    background-repeat: no-repeat;
    background-size: 1920px 400px;
    background-position: center;
  }
  .title-csxz {
    background-image: url('../../assets/images/participate/title-csxz.png');
  }
  .title-jcss {
    background-image: url('../../assets/images/participate/title-jcss.png');
  }
  .title-sjdw {
    background-image: url('../../assets/images/participate/title-sjdw.png');
  }
  .intro-container {
    color: #666;
    line-height: 2.1;
    text-indent: 2em;
    span {
      // font-size: 1.5em;
      color: var(--main-color-2);
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .block-csxz {
    background-image: url('../../assets/images/participate/bg-csxz.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .block-intro {
      margin-top: 80px;
      // margin-bottom: 100px;
      display: flex;
      .block-column {
        width: 240px;
        display: flex;
        flex-direction: column;
        .photo,
        .desc {
          height: 240px;
        }
        .photo {
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .desc {
          color: #fff;
          background: #1d84d5;
          padding: 18px;
          .desc-title {
            text-align: center;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .desc-info {
            font-size: var(--font-size-s);
            line-height: 1.8;
          }
        }
        &:nth-child(2n) {
          .photo {
            order: 1;
          }
          .desc {
            background: #e60000;
          }
        }
      }
    }
  }
  .block-jcss {
    &::before {
      content: '';
      display: table;
    }
    background-image: url('../../assets/images/participate/bg-jcss.jpg');
    background-size: cover;
    background-position: center;
    min-height: 985px;
    margin-bottom: -300px;
    padding-bottom: 300px;
  }

  /deep/.swiper-button-next,
  /deep/.swiper-button-prev {
    width: 40px;
    height: 54px;
    margin-top: -27px;
    background-size: 100% 100%;
    pointer-events: initial !important;
  }
  /deep/.swiper-button-next {
    background-image: url('../../assets/images/arrow-right.png');
    right: 0;
  }
  /deep/.swiper-button-prev {
    background-image: url('../../assets/images/arrow-left.png');
    left: 0;
  }
}
.btn-more-sponsor {
  background: #3a93ff;
  letter-spacing: 4px;
  font-weight: bold;
  height: 50px;
  display: block;
  width: 200px;

  border-radius: 25px;
  margin: 50px auto;
  color: #fff;
  line-height: 50px;
  text-align: center;
}

@media (max-width: 768px) {
  .page {
    .banner-top {
      height: 2.68rem;
      background-size: 12.864rem 2.68rem;
    }
    .title-csxz {
      background-image: url('../../assets/images/participate/title-csxz-m.png');
    }
    .title-jcss {
      background-image: url('../../assets/images/participate/title-jcss-m.png');
    }
    .title-sjdw {
      background-image: url('../../assets/images/participate/title-sjdw-m.png');
    }
    .intro-container {
      span {
        font-size: 1em;
      }
    }

    .block-csxz {
      .block-intro {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        display: block;

        .block-column {
          width: 100%;
          flex-direction: row;

          .photo,
          .desc {
            height: 2.4rem;
          }
          .photo {
            width: 2.4rem;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .desc {
            padding: 0.1rem 0.15rem 0;

            flex: 1;
            .desc-title {
              text-align: left;
              margin-bottom: 0.1rem;
            }
            .desc-info {
              line-height: 1.5;
              font-size: var(--font-size-s-m);
            }
          }
        }
      }
    }
    .block-jcss {
      min-height: auto;
      margin-bottom: -4rem;
      padding-bottom: 4rem;
    }

    /deep/.swiper-button-next,
    /deep/.swiper-button-prev {
      width: 0.4rem;
      height: 0.54rem;
      margin-top: -0.27rem;
      background-size: 100% 100%;
    }
    /deep/.swiper-button-next {
      background-image: url('../../assets/images/arrow-right.png');
      right: 0;
    }
    /deep/.swiper-button-prev {
      background-image: url('../../assets/images/arrow-left.png');
      left: 0;
    }

    .btn-more-sponsor {
      letter-spacing: 0.02rem;
      height: 0.8rem;
      width: 3.2rem;
      border-radius: 0.4rem;
      margin: 0.5rem auto 0;
      line-height: 0.8rem;
    }
  }
}
</style>
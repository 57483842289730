<template>
  <div class="timeline" v-if="timelineData">
    <div class="timeline-container">
      <div class="timeline-container-inner">
      <div class="timeline-item" v-for="item in timelineData" :key="item.id">
        <div class="year">{{ item.year }}</div>
        <div class="info">
          <div class="location">{{ item.province }}</div>
          <div class="desc">{{ item.content }}</div>
          <div class="img">
            <img :src="item.imageUrl" alt="" />
          </div>
        </div>
      </div>
    </div>
    </div>
   
    <div class="timeline-btn arrow-left" @click="left"></div>
    <div class="timeline-btn arrow-right" @click="right"></div>
   
  </div>

</template>

<script>
import molawareScroller from './../../utils/molaware.scroller.js'
var scroller ;
import $ from 'jquery'
export default {
  props: {
    timelineData: {
      type: Array,
      require: true
    },

  },
  data () {
    return {
      baseUrl: this.$VUE_APP_PUBLIC,
    }
  },
  components: {

  },
  methods: {
    scrollTo(el){
      if($('.timeline-container-inner').width() != 0) {
        //当前dom的宽度 
        var curWidth = $('.timeline-container-inner').width()
        //timeline-container-inner距离左侧的距离
        var curLeft = $('.timeline-container-inner').css('transform')
        var curLeftNum = parseInt(curLeft.split(',')[4])
        //每次移动一屏的距离，不够一屏的时候移动剩余的距离
        var moveWidth = $('.timeline-container').width()
        if(!curLeftNum){
          curLeftNum=0
          if(el == 'left'){
            return
          }
        }
        //获取剩余的距离 
          var surplusWidth = (el == 'left' ? Math.abs(curLeftNum) : curWidth - Math.abs(curLeftNum) - moveWidth); 
         if (surplusWidth < moveWidth) { moveWidth = surplusWidth; } 
         //根据移动的距离计算移动的时间 
         var time = 1500 * moveWidth / 1200 / 1000; 
        if(el == 'left'){
        scroller.fromTo({ x: Math.abs(curLeftNum) ,y:0 }, { x:Math.abs(curLeftNum)  - moveWidth,y:0 },time,null )
        }else{
        scroller.fromTo({ x: Math.abs(curLeftNum) ,y:0 }, { x:Math.abs(curLeftNum)  + moveWidth,y:0 },time,null )

        }
      }
      


    },
    right(){
      this.scrollTo('right')
      
    },
    left(){
      this.scrollTo('left')

    },

  },
  updated() {
    if($('.timeline-container-inner').width() != 0) {
       scroller = new molawareScroller('.timeline-container', { damping: 1, scale: 1, max: $('.timeline-container-inner').width() - $('.timeline-container').width(), min: 0, directionX: 1, directionY: 0, moveDirection: -1 })
      $('body').on('mouseup', function () {
        scroller.status.run = 0
      })
      scroller.change = function (value) {
        var curValue = this.config.directionX ? value.x : value.y

        $('.timeline-container-inner').css({
          'transform': 'translate3d(-' + curValue + 'px,0,0)'
        })
      }
    }
  },
}
</script>


<style scoped lang="less">
.timeline{
  position: relative;
}
.timeline-btn{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  &.arrow-left{
    left: -80px;
    background-image: url('../../assets/images/arrow-left.png');
    background-repeat: no-repeat;
    background-position: center;
  }
  &.arrow-right{
    right: -80px;
    background-image: url('../../assets/images/arrow-right.png');
    background-repeat: no-repeat;
    background-position: center;
  }
}
.timeline-container {
  position: relative;
  height: auto;

  white-space: nowrap;
  overflow: hidden;
  .timeline-container-inner {
    background-image: url('../../assets/images/about/ruler.png');
    background-repeat: repeat-x;
    background-attachment: local;
    background-position: left center;
    width: fit-content;
  }
  .timeline-item {
    width: 362px;
    height: 320px;
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-left: -152px;
    user-select: none;
    &:nth-child(1) {
      margin-left: 0;
    }
    &:nth-child(2n) {
      margin-top: 400px;

      .year {
        background-image: url('../../assets/images/about/icon-marker2.png');
        background-position: top center;
        padding-top: 272px;
      }
      .info {
        bottom: auto;
        top: 0;
      }
    }
    .year {
      width: 105px;
      text-align: center;
      font-size: 35px;
      color: #e60012;
      font-weight: bold;
      display: inline-block;
      background-image: url('../../assets/images/about/icon-marker.png');
      background-repeat: no-repeat;
      background-position: bottom center;
      height: 320px;
      vertical-align: top;
    }
    .info {
      display: inline-block;
      white-space: normal;
      width: 252px;
      position: absolute;
      bottom: 0;
      .location {
        color: #3681ff;
        font-size: var(--font-size-l);
        font-weight: bold;
        letter-spacing: 2px;
        margin-bottom: 8px;
      }
      .desc {
        font-size: var(--font-size-s);
        margin-bottom: 10px;
      }
      img {
        max-width: 100%;
        user-select: none;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .timeline-btn{
    display: none;
  }
  .timeline-container {
    height: auto;
    .timeline-container-inner {
      background-position: left bottom;
    }
    .timeline-item {
      width: 7rem;
      height: 6rem;
      margin-bottom: 1rem;
      margin-left: 0;

      &:nth-child(1) {
        margin-left: 0;
      }
      &:nth-child(2n) {
        margin-top: 0;

        .year {
          background-image: url('../../assets/images/about/icon-marker.png');
          background-position: bottom center;

          padding-top: 0;
        }
        .info {
          bottom: 0;
          top: auto;
        }
      }
      .year {
        width: 1.6rem;
        text-align: center;
        font-size: 0.6rem;

        height: 6rem;
        vertical-align: bottom;
      }
      .info {
        display: inline-block;
        white-space: normal;
        width: 252px;
        position: absolute;
        bottom: 0;
        .location {
          color: #3681ff;
          font-size: var(--font-size-l);
          font-weight: bold;
          letter-spacing: 2px;
          margin-bottom: 8px;
        }
        .desc {
          font-size: var(--font-size-s);
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
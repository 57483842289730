<template>
<div ref="tabComponent">
  <div class="tab-header-container">
<div class="tab-header" ref="tabHeader">
  <slot name="tab-header" >tab标题</slot>
  </div>
  
  </div>

<div class="tab-content" ref="tabContent">
  <slot name="tab-content">tab内容</slot>
</div>

</div>
  
</template>

<script>
export default {
  name: "SwiperList",
  mounted(){
    // 初始化tab切换。
  
    const _this = this;
    const tabArgs = this.$refs.tabComponent.querySelectorAll('.tab-header > *');
    tabArgs.forEach(element => {
      element.addEventListener('click',function(){
        tabArgs.forEach(e=>{
          e.classList.remove('active');
        });
        this.classList.add('active');
        _this.changeTab(this.getAttribute('tab-id'));
      });
    });
    
  },
methods:{
  changeTab(tabId){
    const tab = this.$refs.tabComponent.querySelector('.tab-content > *[tab-id="'+tabId+'"]');
    const tabs = this.$refs.tabComponent.querySelectorAll('.tab-content > *');
    tabs.forEach(e=>{
          e.classList.remove('active');
        });

    tab.classList.add('active');
  }
}
};
</script>

<style scoped lang="less">
.tab-header-container{
  text-align:center;
  margin-bottom:40px;
}
.tab-header{
  text-align:center;
  display: inline-block;
  margin:0 auto;
  background: linear-gradient(180deg, #EDF8FF 0%, #D0DFF1 99%);
border-radius: 22px;
 overflow:hidden;
 
  >div{
    display: inline-block;
    color:#666666;
    letter-spacing: 2px;
    font-weight: bold;
    cursor: pointer;
    height: 44px;
    line-height:44px;
    padding:0 50px;

    &.active{
      background:var(--main-color-2) !important;
      cursor:default;
      color:#fff;
    }
    &:hover{
      background:rgba(255,255,255,.3)
    }
  }
   
}
.tab-content{
  >*{
    display:none;
  }
  >*.active{
    display:block;
  }
}


@media (max-width: 768px) {
      .tab-header-container{

      margin-bottom:.54rem;
    }
    .tab-header{
    
    border-radius: .32rem;
    overflow:hidden;
    white-space: nowrap;
    
      >div{

        letter-spacing: .02rem;
        font-weight: normal;
        height: .64rem;
        line-height:.64rem;
        padding:0 .2rem;
        font-size:var(--font-size-m-m);

        &.active{
          background:var(--main-color-2) !important;
          cursor:default;
          color:#fff;
        }
        &:hover{
          background:rgba(255,255,255,.3)
        }
      }
      
    }
    .tab-content{
      >*{
        display:none;
      }
      >*.active{
        display:block;
      }
    }

}
</style>

<template>
 <DataList :dataList="fileList" showType="TextList" :totalCount="count" :hasPagination="true" @handlePageChange="handlePageChange"/>
</template>

<script>
import DataList from '../../components/DataList/DataList'
import {getfileList} from '../../api/competition'
export default {
  data () {
    return {
      fileList : [],
      page: 1,
      size: 10,
      type : 3,
      title: '',
      count: 0
    }
  },
  components: {
    DataList
  },
  created () {
    this.getFilePageList()
  },
  methods :{
    // 获取活动报道分页列表
    async getFilePageList () {
      const res = await getfileList(this.page, this.size, this.type, this.title)
      this.fileList = res.data.list
      this.count = res.data.count
    },
    // 分页
    handlePageChange(currentPage) {
      this.page = currentPage
      this.getFilePageList()
    }
  }
}
</script>


<style scoped lang="less">

</style>
<template>
  <div class="card-container main-width-container">
    <component
      :is="cardType"
      v-for="item in dataList"
      :key="item.id"
      :cardData="item"
      :showDate="showDate"
      :showView="showView"
      :contentType="contentType"
    ></component>
  </div>
</template>

<script >
import CardItem from './CardItem.vue'
import CardItemPerson from './CardItemPerson.vue'
import CardItemSchool from './CardItemSchool.vue'
export default {
  name: 'CardList',
  components: {
    CardItem, CardItemPerson, CardItemSchool
  },
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    showSubType: {
      type: String,
      default: null
    },
    showDate: {
      type: Boolean,
      default: true
    },
    // 是否展示浏览数，在部分卡片上使用，默认true（不是每个卡片都有此信息展示）
    showView: {
      type: Boolean,
      default: true
    },
    contentType: {
      type: String,
      default: null
    }
  },
  data () {
    return {

    }
  },
  computed: {
    cardType () {
      return this.showSubType || 'CardItem'
    }
  },


  methods: {

  }
}
</script>

<style scoped lang="less">
@media (max-width: 768px) {
  .card-container {
    width: auto;
  }
}
</style>
<template>
  <div class="page">
    <div class="search-block">
      <div class="title title-search"></div>
      <div class="button" ref="button">
        <div class="active" @click=";(type = 1), getSearchResultList()">
          文件通知({{ newsListData ? newsListData[0].count : '' }})
        </div>
        <div @click=";(type = 2), getSearchResultList()">活动报道({{ newsListData ? newsListData[1].count : '' }})</div>
        <div @click=";(type = 3), getSearchResultList()">名单公示({{ newsListData ? newsListData[2].count : '' }})</div>
        <div @click=";(type = 4), getSearchResultList()">活动影像({{ newsListData ? newsListData[3].count : '' }})</div>
        <div @click=";(type = 5), getSearchResultList()">往届照片({{ newsListData ? newsListData[4].count : '' }})</div>
        <!-- <div @click=";(type = 6), getSearchResultList()">往届视频({{ newsListData ? newsListData[5].count : '' }})</div> -->
      </div>
      <div :class="type == 4 || type == 5 || type == 6 ? 'tab-content activity-container' : 'tab-content'">
        <DataList
          v-if="type == 1 && newsListData"
          :dataList="showData.list"
          showType="TextList"
          :totalCount="showData.count"
          linkPattern="/article/{id}"
          :pageSize="size"
          :page="filePage"
          :hasPagination="true"
          @handlePageChange="handleFilePagination"
        ></DataList>
        <DataList
          v-if="type == 2 && newsListData"
          :dataList="showData.list"
          showType="TextList"
          :totalCount="showData.count"
          linkPattern="/article/{id}"
          :pageSize="size"
          :page="activityPage"
          :hasPagination="true"
          @handlePageChange="handleActivityPagination"
        ></DataList>
        <DataList
          v-if="type == 3 && newsListData"
          :dataList="showData.list"
          showType="TextList"
          :totalCount="showData.count"
          linkPattern="/article/{id}"
          :pageSize="size"
          :page="nameListPage"
          :hasPagination="true"
          @handlePageChange="handleNameListPagination"
        ></DataList>
        <DataList
          v-if="type == 4 && newsListData"
          :dataList="showData.list"
          :linkPattern="'/news/video/{id}'"
          showType="CardList"
          :pageSize="12"
          :totalCount="showData.count"
          :hasPagination="true"
          :page="resourcePage"
          @handlePageChange="handleResourcePagination"
          type="activity"
        ></DataList>
        <DataList
          v-if="type == 5 && newsListData"
          :dataList="showData.list"
          :linkPattern="'/news/photo/{id}'"
          showType="CardList"
          :pageSize="12"
          :totalCount="showData.count"
          :hasPagination="true"
          :page="photoPage"
          @handlePageChange="handlePhotoPagination"
          type="activity"
        ></DataList>
        <DataList
          v-if="type == 6 && newsListData"
          :dataList="showData.list"
          :linkPattern="'/news/video/{id}'"
          showType="CardList"
          :pageSize="12"
          :page="videoPage"
          :totalCount="showData.count"
          :hasPagination="true"
          @handlePageChange="handleVideoPagination"
          type="activity"
        ></DataList>
      </div>
    </div>
  </div>
</template>

<script>
import { getSearchResult } from '../api/competition'
import DataList from '../components/DataList/DataList.vue'
export default {
  data () {
    return {
      type: 1,
      // 分页
      filePage: 1,
      size: 10,
      nameListPage: 1,
      photoPage: 1,
      videoPage: 1,
      activityPage: 1,
      resourcePage: 1,
      title: this.$route.query.keyword,
      newsListData: null,
      showData: null,
      page: null,
    }
  },
  components: {
    DataList
  },
  methods: {
    // 获取搜索结果 type==1 文件通知 type==2 活动报道 type == 3 名单公示 type==4 活动影像 type==5 往届照片 type == 6 往届视频
    async getSearchResultList () {
      if (this.type == 1) {
        this.page = this.filePage
      } else if (this.type == 2) {
        this.page = this.activityPage
      } else if (this.type == 3) {
        this.page = this.nameListPage
      } else if (this.type == 4) {
        this.page = this.resourcePage
      } else if (this.type == 5) {
        this.page = this.photoPage
      } else if (this.type == 6) {
        this.page = this.videoPage
      }
      const res = await getSearchResult(this.page, this.type, this.title)
      this.newsListData = res.data
      // 所有类型一起返回，需要根据type去做判断筛选
      this.newsListData.forEach(item => {
        if (item.type == this.type) {
          this.showData = item
        }
      })
    },
    //文件通知分页 
    handleFilePagination (val) {
      this.filePage = val
      this.getSearchResultList()
    },
    //活动报道分页 
    handleActivityPagination (val) {
      this.activityPage = val
      this.getSearchResultList()
    },
    //名单公示分页 
    handleNameListPagination (val) {
      this.nameListPage = val
      this.getSearchResultList()
    },
    //活动影像分页 
    handleResourcePagination (val) {
      this.resourcePage = val
      this.getSearchResultList()
    },
    //往届照片分页 
    handlePhotoPagination (val) {
      this.photoPage = val
      this.getSearchResultList()
    },
    //往届视频分页 
    handleVideoPagination (val) {
      this.videoPage = val
      this.getSearchResultList()
    },
  },
  created () {
    this.getSearchResultList()
  },
  mounted () {
    const tabArgs = this.$refs.button.querySelectorAll('.button > *')
    tabArgs.forEach(element => {
      element.addEventListener('click', function () {
        tabArgs.forEach(e => {
          e.classList.remove('active')
        })
        this.classList.add('active')
      })
    })
  }
}
</script>

<style lang="less" scoped>
.page {
  background: url('../assets/images/bg.png') no-repeat center;
  background-size: 100% 100%;
  .search-block {
    width: 1200px;
    margin: auto;

    .title-search {
      background: url('../assets/images/list/title-ssjg.png') no-repeat center;
      height: 74px;
      margin-bottom: 45px;
    }
  }
  .button {
    width: 1034px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    div {
      width: 138px;
      height: 44px;
      background: #d1e3f5;
      border-radius: 20px;
      color: #3d7dcd;
      text-align: center;
      line-height: 44px;
      cursor: pointer;
    }
    .active {
      background: #3d7dcd;
      color: #fff;
    }
  }
  .tab-content {
    background-color: #ffffff;
    min-height: 458px;
    border-radius: 15px;
    margin-top: 27px;
    padding: 40px;
  }
  .activity-container {
    background: none;
    padding: 0;
    padding-top: 30px;
  }
}

@media (max-width: 768px) {
  .page {
    padding-bottom: 3.7rem;
    .search-block {
      width: 7.5rem;
      .title-search {
        background: url('../assets/images/list/title-ssjg-m.png') no-repeat center;
        background-size: 100% 100%;
        height: 0.74rem;
        margin: 0.64rem auto 0.5rem;
      }
    }
    .button {
      width: 6.9rem;
      height: 0.81rem;
      margin: auto;
      overflow-x: scroll;
      white-space: nowrap;
      display: block;
      div {
        width: 1.92rem;
        height: 0.61rem;
        margin-right: 0.2rem;
        display: inline-block;
        border-radius: 0.31rem;
        line-height: 0.61rem;
        font-size: 0.24rem;
      }
    }
    .tab-content {
      background: none;
      min-height: 4.5rem;
      border-radius: 0.15rem;
      margin-top: 0.27rem;
      padding: 0.1rem;
      padding-left: 0.3rem;
    }
    .activity-container {
      padding: 0;
      padding-top: 0.3rem;
    }
    .no-more {
      margin-top: 0.3rem;
    }
  }
}
</style>
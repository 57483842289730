import request from '@/utils/request'

// 大赛资讯
export const getfileList = (page, size, type, title) => {
  return request({
    method: 'GET',
    url: '/inform/findInformPage?pageNum=' + page + '&pageSize=' + size + '&informType=' + type + '&title=' + title
  })
}

// 大赛成果
export const getResultList = (page, size, type, home) => {
  return request({
    method: 'GET',
    url: '/result/detail/getPage?page=' + page + '&size=' + size + '&type=' + type + '&home=' + home
  })
}

//大赛成果新
export const getResultListNew = (page, size, type, province) => {
  return request({
    method: 'GET',
    url: '/result/detail/filter?page=' + page + '&size=' + size + '&type=' + type + '&province=' + province
  })
}


// 大赛概况
export const getCompetitionSituation = () => {
  return request({
    method: 'GET',
    url: '/banner/findCondBannerList'
  })
}

// 历史沿革
export const getHistoryList = () => {
  return request({
    method: 'GET',
    url: '/history/findHistory'
  })
}

// 组织机构
export const getInstitution = () => {
  return request({
    method: 'GET',
    url: '/city/findCityOrgan'
  })
}

// 基层赛事
export const getCompetitionList = () => {
  return request({
    method: 'GET',
    url: '/match/findMatch' 
  })
}

// 设奖单位
export const getCompany = () => {
  return request({
    method: 'GET',
    url: '/award/unit/findAwardUnit'
  })
}

// 更多设奖单位
export const getMoreCompany = () => {
  return request({
    method: 'GET',
    url: '/award/unit/findUnitList' 
  })
}

// 颁奖现场
export const getAwardList = (pageNum, size, year) => {
  return request({
    method: 'GET',
    url: '/inform/awardsScenePage?page=' + pageNum + '&size=' + size + '&year=' + year
  })
}


// 查看详情
export const getDetail = (id) => {
  return request({
    method: 'GET',
    url: '/inform/findInformById?id=' + id
  })
}

// 咨询答疑
export const getProblemList = () => {
  return request({
    method: 'GET',
    url: '/problem/find'
  })
}

// 大赛成果详情
export const getResultDetail = (id) => {
  return request({
    method: 'GET',
    url: '/result/detail/detail?id=' + id
  })
}


// 搜索
export const getSearchResult = (page, type, title) => {
  return request({
    method: 'GET',
    url: '/inform/search?page=' + page + '&type=' + type + '&title=' + title
  })
}
// 查看省份
export const getProvince = (params) => {
  return request({
    method: 'GET',
    url: '/result/detail/filter/province',
    params
  })
}
<template>
  <div class="page">
    <div class="article-container main-width-container">
      <div class="article-title">{{ $route.name=='AchievementArticle' ?articleData.name:articleData.title }}</div>
      <div class="article-info">
        <div class="create-time">{{ articleData.addTime }}</div>
        <div class="author">{{ articleData.authorName }}</div>
      </div>
      <div class="article-body" v-html="articleData.content"></div>
      <div class="file" v-if="articleData.fileList && articleData.fileList.length != 0">
        <span>附件：</span>
        <div class="fj">
          <a
          :href="item.url"
          v-for="(item, index) in articleData.fileList"
          :key="index"
          style="display: block; margin-bottom: 10px; color: #25a4bb; "

          >{{ item.name }}</a >
        </div>
     
      </div>
    </div>
  </div>
</template>

<script>
import { getDetail,getResultDetail } from '../api/competition'

export default {
  data () {
    return {
      articleData: []
    }
  },
  components: {

  },
  created () {
    this.getArticleList()
  },
  methods: {
    //根据ID获取数据
    async getArticleList () {
      let res;
      if(this.$route.name=='AchievementArticle'){
        res = await getResultDetail(this.$route.params.id)
      }else{
        res = await getDetail(this.$route.params.id)
      }
      this.articleData = res.data
    }
  }
}
</script>


<style scoped lang="less">
.page {
  background-image: url('../assets/images/bg-common.jpg');
  background-position: center top;
  background-size: cover;
  margin-bottom: -160px;
  padding-bottom: 300px;

  .article-container {
    margin-top: 80px;
    background: #fff;
    border-radius: 20px;
    padding: 40px 60px;
    min-height: 400px;
    // box-shadow:0 10px 30px 10px rgba(0,0,0,.2);

    .article-title {
      // text-align: center;
      // font-size: var(--font-size-xl);
      // margin: 0 0 20px;

      font-size: 24px;
      line-height: 1.8;
      color: #303133;
      padding-bottom: 20px;
      font-weight: 700;
      text-align: center;

    }
    .article-info {
      text-align: center;
      color: #999;
      margin-bottom: 50px;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;

      div {
        display: inline-block;
        margin: 0 10px;
        letter-spacing: 1px;
        font-size: 14px;
        font-family: PingFangSC-Regular;
        color: #606266;
      }
    }

    .article-body {
      // font-size: var(--font-size-l);
      // line-height: 2;
      font-size: 16px;
      font-family: Microsoft YaHei;
      letter-spacing: 0;
      text-align: justify;
      line-height: 26px;
      color: #333;
      /deep/ p{
        line-height: 36px;
        padding-bottom: 20px;
      }
      /deep/img {
        display: block;
        max-width: 100%;
        margin: 20px auto 10px;
      }
    }
    .file {
      
      font-size: 16px;
      margin-top: 10px;
      .fj{
        display: inline-block;
        vertical-align: top;
      }
      span{
        font-size: 16px;
      }
      p{
        font-size: 16px;
         font-family: Microsoft YaHei;
      }
      a{
        font-size: 16px;
         font-family: Microsoft YaHei;
      }
    }
  }
 
}
/deep/ video{
    min-width: 800px !important;
    text-align: center;
  }

@media (max-width: 768px) {
  /deep/ video{
    min-width: inherit !important;
    width: 100% !important;
  }
  .page {
    margin-bottom: -3rem;
    padding-bottom: 4.2rem;
    .title {
      height: 0.72rem;
    }

    .article-container {
      margin-top: 0;
      background: transparent;
      border-radius: 0;
      padding: 0.5rem 0;
      box-shadow: none;
      min-height: 4rem;

      .article-title {
        // text-align: center;
        // font-size: var(--font-size-xl-m);
        // margin: 0 0 0.2rem;
        font-size: .44rem;
    line-height: 1.5;
    color: #303133;
    padding-top: 0.2rem;
    padding-bottom: 0.4rem;
    text-align: left;
      }
      .article-info {
        color: #999;
        margin-bottom: 0.5rem;

        div {
          margin: 0.1rem 0.1rem;
          letter-spacing: 0.02rem;
        }
      }

      .article-body {
        font-size: .32rem;
        line-height:1.5;
        padding: 0;
        text-align: left;
        /deep/ p {
          line-height: 1.8;
          padding-bottom: 0.4rem;
        }
        /deep/img {
          display: block;
          width: 100% !important;
          height: 100% !important;
          margin: 0.1rem auto;
        }
        /deep/table {
          max-width: 100% !important;
        }
      }
      .file {
        font-size: var(--font-size-l-m);
        margin-top: 0.1rem;
        .fj{
          width: 85%;
        }
        span{
        font-size: .32rem;
        line-height: 1.5;
        }
        a{
          font-size: .32rem;
        }
      }
    }
  }
}
</style>
<template>
<div>
  
 <DataList :dataList="fileList" showType="CardList" contentType="video" linkPattern="/news/video/{id}" :totalCount="count" :hasPagination="true" :pageSize="size" @handlePageChange="handlePageChange"/>
</div>

</template>

<script>
import DataList from '../../components/DataList/DataList'
import {getfileList} from '../../api/competition'
export default {
  data () {
    return {
      fileList : [],
      page: 1,
      size: 12,
      type : 6,
      title: '',
      count: 0
    }
  },
  components: {
    DataList
  },
  created () {
    this.getFilePageList()
  },
  methods :{
    // 获取文件通知分页列表
    async getFilePageList () {
      const res = await getfileList(this.page, this.size, this.type, this.title)
      this.fileList = res.data.list
      this.count = res.data.count
    },
    // 分页
    handlePageChange(currentPage) {
      this.page = currentPage
      this.getFilePageList()
    }
  }
}
</script>


<style scoped lang="less">
/deep/.card-container{
  width:auto !important;
  .card-simple{
    margin-right: 30px;
    &:nth-child(3n) {
    margin-right: 0;
    }
  
  }
}

@media (max-width: 768px) {
  /deep/.card-container{

  .card-simple{
    margin-right: 0.282rem;
    &:nth-child(3n) {
    margin-right: 0.282rem;
    }
    &:nth-child(2n) {
    margin-right: 0rem;
    }
  
  }
}
}
</style>
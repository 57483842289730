<template>
  <div :class="'nav-container ' + (isOpen ? 'active' : '')">
    <router-link to="/home">首页</router-link>
    <router-link to="/about"
      >关于大赛
      <ul class="sub-menu">
        <li><router-link to="/about">大赛概况</router-link></li>
        <li><router-link to="/about/lsyg">历史沿革</router-link></li>
        <li><router-link to="/about/zzjg">省级竞赛组织机构</router-link></li>
      </ul>
    </router-link>
    <router-link to="/participate">参与大赛</router-link>
    <router-link to="/achievement">大赛成果</router-link>
    <router-link to="/news"
      >大赛资讯
      <ul class="sub-menu">
        <li><router-link to="/news/wjtz">文件通知</router-link></li>
        <li><router-link to="/news/hdbd">活动报道</router-link></li>
        <li><router-link to="/news/mdgs">名单公示</router-link></li>
        <li><router-link to="/news/hdyx">活动影像</router-link></li>
        <li><router-link to="/news/wjzp">往届照片</router-link></li>
        <!-- <li><router-link to="/news/wjsp">往届视频</router-link></li> -->
      </ul>
    </router-link>
    <router-link to="/qa">咨询答疑</router-link>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean
  },
  data () {
    return {

    }
  },
  watch: {
    $route: {
      handler () {
        this.$emit('isMenuClose', false)
      }
    }
  }


}
</script>

<style scoped lang="less">
.router-link-active {
  color: #fff !important;
  background: url('../../assets/images/header/bg-link.png') no-repeat center;
}

.nav-container a {
  display: inline-block;
  min-width: 74px;
  color: #666666;
  margin-right: 34px;
  line-height: 60px;
  position: relative;
  text-align: center;
}
.nav-container a:hover {
  color: var(--main-color-2);
}
.sub-menu {
  display: none;
  position: absolute;
  background: #fff;
  padding: 15px 0 0;
  left: 50%;
  transform: translateX(-50%);

  li {
    white-space: nowrap;
    line-height: 50px;
    padding: 0 30px;
    &:hover {
      background: #eee;
    }
    a {
      color: #2e60a2 !important;
      background: none;
      min-width: auto;
      margin-right: unset;
      display: block;
      line-height: unset;
    }
  }
}
.nav-container a:hover .sub-menu {
  display: block;
  color: #2e60a2;
}

@media (max-width: 768px) {
  .router-link-active {
    color: #333 !important;
    background: url('../../assets/images/header/bg-link-m.png') no-repeat bottom center;
    background-size: 0.79rem 0.12rem;
    font-size: 1.2em !important;
    font-weight: bold;
  }

  .nav-container {
    width: 100%;
    min-width: auto;
    margin: 0 auto;
    display: none;
    position: absolute;
    background: #fff;
    top: 100%;
    left: 0;
    opacity: 0.9;
    box-shadow: 0 20px 30px 3px rgba(231, 231, 231, 0.3);
    padding-bottom: 0.2rem;
  }
  .nav-container.active {
    display: block;
  }
  .nav-container a {
    display: block;
    text-align: center;
    line-height: 0.8rem;
    margin: 0 0.4rem;
    font-size: var(--font-size-l-m);
  }
  .nav-container a:hover .sub-menu {
    display: none;
  }
  .router-link-exact-active::after {
    height: 2px;
    bottom: 0.05rem;
    border-radius: 0;
  }
}
</style>
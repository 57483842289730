<template>
  <div class="page">
    <div class="banner-top"></div>
    <div class="main-width-container">
      <div class="title title-dscg"></div>
      <TabComponent>
        <template v-slot:tab-header>
          <div tab-id="1" class="active" @click="tabStudent">优秀获奖学生</div>
          <div tab-id="2" @click="yxfdy">优秀科技辅导员</div>
          <div tab-id="3" @click="tabSchool">科技创新优秀学校</div>
        </template>
        <template v-slot:tab-content>
           <!-- 请选择所属省份 -->
             <div class="province-select" >
              <el-select v-model="province" placeholder="请选择所属省份" @change="getProvinceChange" clearable>
                <el-option
                  v-for="item in provinceList"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </div>
          <div tab-id="1" class="active">
            <DataList
              :dataList="studentsList"
              :page="studentsPage"
              showType="CardList"
              showSubType="CardItemPerson"
              :totalCount="studentsCount"
              :hasPagination="true"
              :pageSize="size"
              @handlePageChange="handleStudentsListChange"
            />
          </div>

          <div tab-id="2">
            <DataList
              :dataList="assistantList"
              :page="assistantPage"
              showType="CardList"
              showSubType="CardItemPerson"
              :totalCount="assistantCount"
              :hasPagination="true"
              :pageSize="size"
              @handlePageChange="handleAssistantListChange"
            />
          </div>
          <div tab-id="3">
            <DataList
              :dataList="schoolList"
              :page="schoolPage"
              showType="CardList"
              showSubType="CardItemSchool"
              :totalCount="schoolCount"
              :hasPagination="true"
              :pageSize="size"
              @handlePageChange="handleSchoolListChange"
            />
          </div>
        </template>
      </TabComponent>
    </div>
  </div>
</template>

<script>
import TabComponent from '@/components/TabComponent.vue'
import DataList from '../../components/DataList/DataList'
import {getProvince,getResultListNew} from '../../api/competition'

export default {
  data () {
    return {
       province: '',
      provinceList:[],
      schoolList: [], //学校列表
      assistantList: [], //辅导员列表
      studentsList: [], //学生列表
      studentsPage: 1,
      assistantPage: 1,
      schoolPage: 1,
      size: 12,
      schoolCount: 0,
      studentsCount: 0,
      assistantCount: 0,
      home: false
    }
  },

  components: {
    DataList, TabComponent
  },
  created () {
    this.getStudentsList()
    this.getAssistantList()
    this.getSchoolList()

  },
  mounted(){
    this.getProvinceData(1)
  },
  methods: {
      tabStudent(){
        this.getProvinceData(1)
    this.getStudentsList()

      },
      yxfdy(){
        this.getProvinceData(2)
        this.getAssistantList()

      },
      tabSchool(){
        this.getProvinceData(3)
    this.getSchoolList()

      },
      getProvinceData(id){
        this.province = ''
        let params = {
          type: id
        }
        getProvince(params).then(res=>{
          this.provinceList = res.data
        })
    },
    getProvinceChange(){
      let id = document.querySelector('.active').getAttribute('tab-id')
      if(id == 1){
        this.getStudentsList()
      }else if(id == 2){
        this.getAssistantList()
      }else if(id == 3){
        this.getSchoolList()
      }
    },
    // 获取优秀获奖学生列表
    async getStudentsList () {
      const res = await getResultListNew(this.studentsPage, this.size, 1, this.province)
      this.studentsList = res.data.list
      this.studentsCount = res.data.count
    },
    // 获取辅导员列表
    async getAssistantList () {
      const res = await getResultListNew(this.assistantPage, this.size, 2, this.province)
      this.assistantList = res.data.list
      this.assistantCount = res.data.count
    },
    // 获取学校列表
    async getSchoolList () {
      const res = await getResultListNew(this.schoolPage, this.size, 3, this.province)
      this.schoolList = res.data.list
      this.schoolCount = res.data.count
    },
    // 学生列表分页
    handleStudentsListChange (val) {
      this.studentsPage = val
      this.getStudentsList()
    },
    // 辅导员列表分页
    handleAssistantListChange (val) {
      this.assistantPage = val
      this.getAssistantList()
    },
    // 学校列表分页
    handleSchoolListChange (val) {
      this.schoolPage = val
      this.getSchoolList()
    },
  }
}
</script>


<style scoped lang="less">
.banner-top {
  height: 400px;
  background-image: url('../../assets/images/achievement/banner-achievement.jpg');
  background-repeat: no-repeat;
  background-size: 1920px 400px;
  background-position: center;
}
.title-dscg {
  background-image: url('../../assets/images/home/title-dscg.png');
}
.province-select{
    width: var(--main-width);
    margin: 0 auto;
    margin-bottom: 50px;
    display: block !important;
    /deep/ .el-input__suffix{
      right: 0;
      width:40px;
      background:rgba(82, 147, 210, 1);
    }
    /deep/ .el-select .el-input .el-select__caret{
      color: #fff;
    }
  }

@media (max-width: 768px) {
  .banner-top {
    height: 2.68rem;
    background-size: 12.864rem 2.68rem;
  }
  .title-dscg {
    background-image: url('../../assets/images/home/title-dscg-m.png');
  }
  .province-select{
    width: inherit;
    margin: 0 0.3rem;
    margin-bottom: 0.5rem;
    display: block !important;
    /deep/ .el-input__suffix{
      right: 0;
      width:40px;
      background:rgba(82, 147, 210, 1);
    }
    /deep/ .el-select .el-input .el-select__caret{
      color: #fff;
    }
  }
}


</style>
<template>
<form @submit.prevent="doSearch" method="post">
    <div class="nav-search">
        <input type="text" placeholder="请输入关键字" v-model="keyword" />
        <div class="btn-search" @click="doSearch"></div>
      </div>
      </form>
</template>
<script>
export default {
  data(){
    return{
      keyword:null
    }
    
  },
  methods:{
  doSearch(){
      this.keyword = this.keyword.trim();
      this.$router.push({path: '/search/',query: { keyword:this.keyword}}).catch(()=>{});
      this.keyword = null
    }
  }
}
</script>

<style scoped lang="less">
form{
  display:flex;
}
.nav-search {
      position: relative;
      line-height:60px;
      display: flex;
      align-items: center;
      font-size: 0;
      input {
        width: 308px;
        height: 33px;
        background: #eef0f6;
        border-radius: 16px;
        padding-left: 40px;
        border: none;
        box-sizing: border-box;
        outline: none;
        display: block;
      }
      input:focus {
        background: #ffffff;
        border: 1px solid #4ea2c9 !important;
      }
      .btn-search {
        background:url("@/assets/images/header/search.png") no-repeat center;
        background-size:18px 19px;
        position: absolute;
        top: 50%;
        left: 4px;
        width: 32px;
        height: 32px;
        margin-top:-15px;
      }
    }
  @media (max-width: 768px) {
    form{
      display: block;
    }
    .nav-search {
      width:inherit;
      line-height:.495rem;
      input {
        width: 100%;
        height: .495rem;
        border-radius: .2475rem;
        padding-left: 0.64rem;  
      }
 
      .btn-search {
        background-image:url("@/assets/images/header/search-m.png");
        background-size:.29rem .3rem;
        width: .495rem;
        height: .495rem;
        margin-top:-.255rem;
      }
    }

  }
</style>
/*
多用途带阻尼滚动控件。www.molware.com
依赖于gsap.
2020/07/30 新增横竖屏切换后，对应计算问题
2021/4/14 增补reset方法
2021/5/13 增补moveEnd时的回调
*/
import gsap from 'gsap';
var molawareScroller = function (targetDom, options) {
	console.info('molaware scroller v1.3 www.molaware.com');


	this.targetDom = document.querySelector(targetDom);
	//记录初始状态。
	this.origin = {

	}
	this.config = {
		//阻尼值，0为无阻尼
		damping: 1,
		//是否允许横向移动
		directionX: 0,
		//是否允许纵向移动
		directionY: 1,
		//移动放大值.数字越小，移动对应的值越小。
		scale: 1.0,
		//移动允许的最小值
		min: null,
		//移动允许的最大值
		max: null,
		//是否颠倒移动与动画方向。
		moveDirection: 1,
	};

	if (options) { Object.assign(this.config, options); };
	//记录初始状态。
	Object.assign(this.origin, this.config);

	this.status = {
		enable: 1,
		run: 0,
		start: { x: null, y: null },
		touchPos: { x: null, y: null },
		offset: { x: 0, y: 0 },
		offsetStart: { x: 0, y: 0 },
		distance: { x: 0, y: 0 }
	};


	var vecAdd = function (vec2a, vec2b) { return { x: vec2a.x + vec2b.x, y: vec2a.y + vec2b.y } };
	var vecDiff = function (vec2a, vec2b) { return { x: vec2a.x - vec2b.x, y: vec2a.y - vec2b.y } };
	var vecMul = function (vec2a, muliti) { return { x: vec2a.x * muliti, y: vec2a.y * muliti } };
	var clamp = function (min, max, val) {
		if (min != null && val < min) { val = min; }
		else if (max != null && val > max) { val = max; }
		return val;
	}
	var vecClamp = function (min, max, val) {
		if (min != null && val.x < min) { val.x = min; }
		else if (max != null && val.x > max) { val.x = max; }

		if (min != null && val.y < min) { val.y = min; }
		else if (max != null && val.y > max) { val.y = max; }
		return val;
	}
	var scroller = this;
	var touchStartHandler = function (e) {
		if (!scroller.status.enable) return;

		//e.stopPropagation();
		if (e.type == 'touchstart')
			e = e.touches[0];
		else if (e.type == 'mousedown') {

		}
		scroller.status.run = 1;
		//触摸开始的位置
		scroller.status.start = { x: e.screenX, y: e.screenY };
		//触摸开始时的offset

		Object.assign(scroller.status.offsetStart, scroller.status.offset);
	}
	var touchEndHandler = function (e) {
		scroller.status.run = 0;
	}
	var touchMoveHandler = function (e) {
		if (!scroller.status.run) return;
		if (e.type == 'touchmove')
			e = e.touches[0];
		else if (e.type == 'mousemove') {

		}


		scroller.status.touchPos = { x: e.screenX, y: e.screenY };
		move();
	}
	var target;
	var move = function () {
		target = { x: 0, y: 0 };
		scroller.status.distance = vecDiff(scroller.status.touchPos, scroller.status.start);
		scroller.status.distance = vecMul(scroller.status.distance, scroller.config.scale * scroller.config.moveDirection);


		scroller.status.drag = 1;
		if (!scroller.config.damping) {

			scroller.status.offset = vecAdd(scroller.status.offset, scroller.status.distance);
			scroller.status.offset = vecClamp(scroller.config.min, scroller.config.max, scroller.status.offset);
			if (scroller.config.directionX) {
				target.x = scroller.status.offset.x;
			}
			if (scroller.config.directionY) {

				target.y = scroller.status.offset.y;
			}

			scroller.change(scroller.status.offset);
			//下次touchmove时的初始位置，设置为当前位置。
			Object.assign(scroller.status.start, scroller.status.touchPos);

			scroller.status.drag = 0;
		}

		else {

			target = vecAdd(scroller.status.offsetStart, scroller.status.distance);
			target = vecClamp(scroller.config.min, scroller.config.max, target);
	
			scroller.status.offset = vecClamp(scroller.config.min, scroller.config.max, scroller.status.offset);



			if (!scroller.config.directionX) {
				target.x = scroller.status.offset.x;
			}
			if (!scroller.config.directionY) {
				target.y = scroller.status.offset.y;
			}

			gsap.to(scroller.status.offset, {
				duration: scroller.config.damping,
				overwrite: true,
				x: target.x,
				y: target.y,
				onUpdate: function () {
					scroller.change(scroller.status.offset);
				},
				onComplete: function () {
					scroller.status.drag = 0;
					if (scroller.moveEndCallback) {
						scroller.moveEndCallback();
					}

				}
			});
		}
	}

	this.getOffset = function () {
		return this.status.offset;
	}
	this.change = function (value) {

	}
	this.reset = function () {
		delete this.status;
		this.status = {
			"enable": 1,
			"run": 0,
			"start": {
				"x": null,
				"y": null
			},
			"touchPos": {
				"x": null,
				"y": null
			},
			"offset": {
				"x": 0,
				"y": 0
			},
			"offsetStart": {
				"x": 0,
				"y": 0
			},
			"distance": {
				"x": 0,
				"y": 0
			}
		}
		this._cur = null;
	}
	/*横竖屏计算轴切换*/
	this.changeDirection = function () {
		var temp = scroller.status.offset.x;
		scroller.status.offset.x = scroller.status.offset.y;
		scroller.status.offset.y = temp;


		if (scroller._tween) {
			if (scroller.status.drag) {

				//1. 停止动画执行。
				scroller._tween.kill();
				//2. 重新开始新动画
				//2.1 计算duration
				var dur = (1 - scroller._tween.ratio) * scroller._tween._dur;
				var easeFunction = scroller._tween.vars.ease;
				scroller.status.enable = 0;
				var tweenObjFrom = {};
				var tweenObjTo = {};
				var easeFunction = "none";
				var newMin = 0;
				if (window.innerWidth > window.innerHeight) {
					tweenObjFrom = { x: scroller._cur || 0 };
					tweenObjTo = { x: scroller._tween.vars.y }
					newMin = scroller._tween.vars.y;
				}
				else {
					tweenObjFrom = { y: scroller._cur || 0 };
					tweenObjTo = { y: scroller._tween.vars.x }
					newMin = scroller._tween.vars.x;

				}

				scroller.fromTo(tweenObjFrom, tweenObjTo, dur, easeFunction, function () {
					scroller.config.min = newMin;
					scroller.status.enable = 1;
				});
			}
		}
	}
	//停止当前tween.强制定制拖拽等。
	this.stop = function () {
		//停止动画。
		gsap.killTweensOf(scroller.status.offset);
		scroller.status.run = 0;
	}
	//直接跳转到对应的位置
	this.to = function (val) {

		scroller.status.offset = val;
		scroller.status.offset = vecClamp(scroller.config.min, scroller.config.max, scroller.status.offset);
		scroller.change(val);
	}
	this.fromTo = function (valFrom, valTo, duration, easeFunc, callback) {
		//初始位置

		scroller.status.drag = 1;
		scroller.to(valFrom);
		//缓慢改变到目标位置。
		var cur = valFrom;
		var target = valTo;
		var tween = gsap.to(valFrom, {
			duration: duration || scroller.config.damping,
			overwrite: true,
			ease: easeFunc,
			x: target.x,
			y: target.y,
			onUpdate: function () {
				scroller.change(scroller.status.offset);
			},
			onComplete: function () {
				scroller.status.drag = 0;

				if (callback) { callback(); }
			}
		});
		this._tween = tween;
		return tween;
	}

	this.targetDom.addEventListener('touchstart', touchStartHandler);
	this.targetDom.addEventListener('touchmove', touchMoveHandler);
	this.targetDom.addEventListener('touchend', touchEndHandler);

	this.targetDom.addEventListener('mousedown', touchStartHandler);
	this.targetDom.addEventListener('mousemove', touchMoveHandler);
	this.targetDom.addEventListener('mouseup', touchEndHandler);
}
export default molawareScroller;
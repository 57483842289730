<template>
  <div class="page">
    <div class="video-container main-width-container">
     <div class="video-title">{{videoData.title}}</div>
     <div class="video-info">
      <div class="create-time">{{videoData.addTime}}</div>
      <div class="author">{{videoData.authorName}}</div>
     </div>
     <div class="desc">{{videoData.content}}</div>
     <video class="video-body" :src="videoData.videoUrl" autoplay controls>
 
     </video>
     
    </div>
  </div>
</template>

<script>
import { getDetail } from '../api/competition'
export default {
  data () {
    return {
      videoData:[],
      baseUrl:this.$VUE_APP_PUBLIC,
    }
  },
  components: {

  },
  created () {
    this.getVideoList()
  },
  methods :{
    //根据ID获取数据
    async getVideoList () {
      const res = await getDetail(this.$route.params.id)
      this.videoData = res.data
    }
  }
}
</script>


<style scoped lang="less">
.page {
  background-image:url("../assets/images/bg-common.jpg");
  background-position: center top;
  background-size:cover;
  margin-bottom: -160px;
  padding-bottom:300px;
.desc{
  font-size: 16px;
    font-family: Microsoft YaHei;
    letter-spacing: 0;
    text-align: justify;
    line-height: 26px;
    margin-bottom: 20px;
    color: #333;

    }

.video-container{
  margin-top:80px;
  background:#fff;
  border-radius:20px;
  padding:50px 50px 50px;
 

  .video-title{
    font-size: 24px;
    line-height: 1.8;
    color: #303133;
    padding-bottom: 20px;
    font-weight: 700;
    text-align: center;

  }
  .video-info{
    text-align:center;
    color:#999;
    margin-bottom:50px;
    border-bottom:1px solid #eee;
    padding-bottom:20px;

    div{
      display: inline-block;
        margin: 0 10px;
        letter-spacing: 1px;
        font-size: 14px;
        font-family: PingFangSC-Regular;
        color: #606266;
    }
  }

  .video-body{
    width: 100%;
    height: 100%;
     /deep/img {
      display:block;
      max-width:100%;
      margin:20px auto 10px;
    }
  }
  

}
}

@media (max-width: 768px) {
  .page {

  margin-bottom: -3rem;
    padding-bottom: 4.2rem;
    .desc{
      margin-bottom:.3rem;
      margin-bottom: 0.3rem;
          font-size: 0.32rem;
          line-height: 1.5;
          padding: 0;
          text-align: left;
    }

.video-container{
  margin-top:0;
  background:transparent;
  border-radius:0;
  padding:.5rem 0;
  box-shadow:none;

  .video-title{
    text-align:center;
    font-size:var(--font-size-xl-m);
    margin:0 0 .2rem;

  }
  .video-info{
    text-align:center;
    color:#999;
    margin-bottom:.5rem;
    padding-bottom:.3rem;

    div{
      margin:.1rem .1rem;
      letter-spacing: .02rem;

    }
  }

  .video-body{
    width: 100%;
    height: 100%;
     /deep/img {
      display:block;
      max-width:100%;
      margin:.1rem auto;
    }
  }
  

}

  }
}
</style>
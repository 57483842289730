<template>
  <div class="page">
    <div class="banner-top"></div>
    <div class="main-width-container">
      <div class="title title-zxdy"></div>
      <div class="qa-item" v-for="item in qaData" :key="item.id">
        <div class="question">{{ item.title }}</div>
        <div class="answer" v-show="!item.collapse" v-html="item.content"></div>
        <div :class="'btn-expend ' + (item.collapse ? 'collapse' : '')" @click="expendOrCollapseItem(item)"></div>
      </div>
      <div class="text-center">
        <div class="ques-other">如有其他问题，请发邮件至大赛邮箱cyscc-castic@cast.org.cn询问。</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProblemList } from '../../api/competition'

export default {
  data () {
    return {
      qaData: []
    }
  },
  components: {

  },
  created () {
    this.getProblem()
  },
  methods: {
    expendOrCollapseItem (item) {
      if (item.collapse) {
        this.$set(item, 'collapse', !item.collapse)
      }
      else {
        this.$set(item, 'collapse', true)
      }
    },
    async getProblem () {
      const res = await getProblemList()
      this.qaData = res.data
    }
  },
}
</script>


<style scoped lang="less">
.page {
  background-image: url('../../assets/images/qa/bg-zxdy.jpg');
  background-position: center top;
  background-size: cover;
  margin-bottom: -160px;
  padding-bottom: 250px;
}
.banner-top {
  height: 400px;
  background-image: url('../../assets/images/qa/banner-top.jpg');
  background-repeat: no-repeat;
  background-size: 1920px 400px;
  background-position: center;
}
.title-zxdy {
  background-image: url('../../assets/images/qa/title-zxdy.png');
}
.qa-item {
  position: relative;
  background-image: url('../../assets/images/qa/QA.png');
  background-repeat: no-repeat;
  background-size: 97px 89px;
  background-position: left top;
  padding-left: 120px;
  min-height: 100px;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 2px solid #eee;
  .question {
    font-size: var(--font-size-xl);
    color: var(--main-color-2);
    letter-spacing: 2px;
    margin-bottom: 15px;
  }
  .answer {
    line-height: 2.5;
    color: #333;
  }
  .btn-expend {
    background-image: url('../../assets/images/qa/arrow-down.png');
    background-repeat: no-repeat;
    background-size: 24px 13px;
    background-position: center;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .btn-expend.collapse {
    transform: rotate(180deg);
  }
}
.ques-other {
  background: #9eafcb;
  height: 50px;
  display: inline-block;
  padding: 0 15px 0 50px;
  border-radius: 25px;
  margin: 0 auto;
  color: #fff;
  line-height: 50px;
  text-align: center;
  background-image: url('../../assets/images/qa/icon-alert.png');
  background-position: 15px center;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .page {
    .banner-top {
      height: 2.68rem;
      background-size: 12.864rem 2.68rem;
    }

    .title-zxdy {
      background-image: url('../../assets/images/qa/title-zxdy-m.png');
    }
    .qa-item {
      background-image: none;

      padding-left: 0;
      min-height: auto;
      margin-bottom: 0.3rem;
      padding-bottom: 0.3rem;
      border-bottom: 1px solid #eee;
      .question {
        font-size: var(--font-size-xl-m);
        font-weight: bold;
        letter-spacing: 0.02rem;
        margin-bottom: 0.25rem;
        margin-right: 0.7rem;
      }

      .btn-expend {
        background-size: 0.288rem 0.156rem;
        background-position: center;
        width: 0.5rem;
        height: 0.5rem;
      }
    }
    .ques-other {
      height: auto;
      letter-spacing: 0.02rem;
      padding: 0.2rem 0.3rem 0.2rem 1rem;
      line-height: 1.8;
      border-radius: 0.3rem;
      text-align: left;
      background-position: 0.25rem center;
    }
  }
}
</style>
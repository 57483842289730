<template>
  <div class="swiper-list">
    <div class="banner-swiper">
      <div class="inner">
        <swiper ref="swiperList" :options="swiperOption" class="banner-swiper" v-if="swiperList.length > 0">
          <!-- slides -->
          <swiper-slide v-for="(item, index) in swiperList" :key="index">
            <img :src="item.imageUrl" style="width: 100%; height: 100%; cursor:pointer" @click="jumpToDetail(item.id)"/>
            <div class="title-swiper">{{ item.title }}</div>
          </swiper-slide>
        </swiper>
      </div>
      <!-- <div class="nav-controller nav-prev"><i class="el-icon-arrow-left"></i></div>
      <div class="nav-controller nav-next"><i class="el-icon-arrow-right"></i></div> -->
      <div class="progress" :style="bgColor"></div>
    </div>
    <div class="banner-list">
      <DataList
        :showDate="false"
        :hasPagination="false"
        :dataList="infoList"
        :totalCount="infoList.length"
        linkPattern='/home/article/{id}'
        v-if="infoList"
      />
      <router-link :to="moreLink" class="more">查看更多></router-link>
    </div>
  </div>
</template>

<script>
import DataList from './DataList/DataList.vue'
export default {
  name: "SwiperList",
  components: {
    DataList,
  },
  props: {
    swiperList: {
      type: Array,
      require: true
    },
    swiperPaginationChange: {
      type: String,
      require: true
    },
    infoList: {
      type: Array,
      require: false,
    },
    paginationColor: {
      type: String,
      default: '#A9C650'
    },
    moreLink: {
      type: String,
      default: '#'
    }
  },

  data () {
    return {
      baseUrl: this.$VUE_APP_PUBLIC,
      swiperOption: {
        observer: true,
        autoplay: {
          delay: 3000,
        },
        loop: true,
        // //左右箭头
        // navigation: {
        //   nextEl: '.nav-next',
        //   prevEl: '.nav-prev'
        // },
        observeParents: true,
        

        pagination: {
          el: `.${this.swiperPaginationChange} .progress`,
        //指示点可点击
          clickable: true,

        }
      },

    }
  },
  computed: {
    bgColor () {
      return {
        '--paginationColor': this.paginationColor
      }
    }
  },
  methods: {
    jumpToDetail (id) {
      this.$router.push({ name: 'Article', params: { id, } })
    }
  },
};
</script>
<style scoped lang="less">
  //   .nav-controller{
  //     position: absolute;
  //     width: 50px;
  //     height: 50px;
  //     margin-top: -20px;
  //     top: 50%;
  //     z-index: 3;
  //     font-size: 30px;
  //     background-color: rgba(0, 0, 0, 0.5);
  //     border-radius: 50%;
  //     text-align: center;
  //     line-height: 50px;
  //     color: #fff;
  //     opacity: 0;
  //     transition: all 0.3s;
  //     cursor: pointer;
  //   &.nav-prev{
  //   left:10px;
  //   transform:translateX(-10px);
  //   }
  //   &.nav-next{
  //   right:10px;
  //   transform:translateX(10px);
  //   }
  // }
  // &:hover .nav-controller{
  //   transform:translateX(0);
  //   opacity:1;
  // }
.swiper-list {
  width: 100%;
  display: flex;
  .banner-swiper {
    position: relative;
    width: 583px;
    height: 329px;
    border-radius: 5px;
    .inner {
      width: 583px;
      height: 329px;
      overflow: hidden;
    }

    .title-swiper {
      position: absolute;
      z-index: 99;
      left: 0;
      bottom: 0;
      padding: 10px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      box-sizing: border-box;
      text-align: center;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .swiper-pagination-bullets {
      padding-top: 10px;
      text-align: center;
      /deep/.swiper-pagination-bullet {
        margin: 0 4px;
        height: 4px;
        width: 30px;
        background-color: var(--paginationColor);
        border-radius: 0%;
      }
      /deep/.swiper-pagination-bullet-active {
        background-color: var(--paginationColor);
      }
    }
  }
  .banner-list {
    // 宽度 = 1200-左侧图片 + margin-left
    width: 807px;
    background-color: #fff;
    margin-left: -190px;
    margin-top: -36px;
    padding-top: 35px;
    padding-left: 220px;
    padding-right: 30px;
    box-shadow: 0px 14px 54px 0px rgba(165, 181, 192, 0.4);
    border-radius: 8px;
    height: 400px;
    box-sizing: border-box;
    line-height: 50px;

    /deep/li {
      letter-spacing: 1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 20px;
      margin-top: 10px;
      padding-bottom: 0;
      padding-top: 0;
      &::before {
        background: var(--main-color-3);
      }
    }
    .more {
      display: block;
      text-align: right;

      font-size: var(--font-size-m);
      color: var(--main-color-3);
      margin-top: 0.2rem;
    }
  }
}

.swiper-list.text-left {
  .banner-swiper {
    order: 1;
  }
  .banner-list {
    order: 0;
    margin-left: 0;
    margin-right: -190px;
    padding-left: 30px;
    padding-right: 220px;
    box-shadow: none;
  }
}
@media (max-width: 768px) {
  .nav-controller{
    display: none;
  }
  .swiper-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    margin: auto;
    .banner-swiper {
      position: relative;
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
      margin: 0 auto;
      .inner {
        width: 100%;
        height: inherit;
        margin: auto;
        overflow: hidden;
      }
      .title-swiper {
        position: absolute;
        left: 0;
        bottom: 0;
        padding-left: 0.25rem;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        box-sizing: border-box;
        text-align: center;
        color: #fff;
        font-size: var(--font-size-l-m);
        line-height: 0.36rem;
      }
      .swiper-pagination-bullets {
        padding-top: 0;
        text-align: center;
        /deep/.swiper-pagination-bullet {
          width: 0.6rem;
          height: 0.06rem;
          background: var(--paginationColor);
          opacity: 0.4;
          border-radius: 0.015rem;
        }
        /deep/.swiper-pagination-bullet-active {
          background-color: var(--paginationColor);
          opacity: 1;
        }
      }
    }
    .banner-list {
      width: 100%;
      position: relative;
      border-radius: 0.04rem;
      height: inherit;
      margin: auto;
      margin-top: 0rem;
      padding: 0rem 0 0.5rem 0;
      line-height: 3;
      box-shadow: none;
    }
  }
  .swiper-list.text-left {
    .banner-list {
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>



import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import About from '../views/about/About.vue'
import Participate from '../views/participate/Participate.vue'
import Sponsor from '../views/participate/Sponsor.vue'
import Achievement from '../views/achievement/Achievement.vue'
import News from '../views/news/News.vue'
import Wjtz from '../views/news/Wjtz.vue'
import Hdbd from '../views/news/Hdbd.vue'
import Mdgs from '../views/news/Mdgs.vue'
import Hdyx from '../views/news/Hdyx.vue'
import Wjzp from '../views/news/Wjzp.vue'
import Wjsp from '../views/news/Wjsp.vue'

import Qa from '../views/qa/Qa.vue'
import Dszc from '../views/about/Dszc.vue'
import Jsgz from '../views/about/Jsgz.vue'
import Kjfd from '../views/about/Kjfd.vue'

import Article from '../views/Article.vue'
import Video from '../views/Video.vue'
import PhotoAlbumn from '../views/PhotoAlbumn.vue'
import SearchResult from '../views/SearchResult.vue'
import { MolaTrack } from '../utils/MolaTrack'  

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      moduleName: '首页',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/home',
    component: Home,
    meta: {
      moduleName: '首页',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/home/article/:id',
    name: 'Article',
    component: Article,
    meta: {
      moduleName: '首页',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/home/video/:id',
    component: Video,
    meta: {
      moduleName: '首页',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/About',
    component: About,
    meta: {
      moduleName: '关于大赛',
      title:'全国青少年科技创新大赛'
    },
  },
  // {
  //   path: '/About/zzjg',
  //   component: About,
  //   meta: {
  //     moduleName: '关于大赛',
  //     title:'全国青少年科技创新大赛'
  //   },
  // },
  // {
  //   path: '/About/lsyg',
  //   component: About,
  //   meta: {
  //     moduleName: '关于大赛',
  //     title:'全国青少年科技创新大赛'
  //   },
  // },
  
  {
    path: '/About/:part',
    component: About,
    meta: {
      moduleName: '关于大赛',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/participate',
    component: Participate,
    meta: {
      moduleName: '参与大赛',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/participate/article/:id',
    name: 'Article',
    component: Article,
    meta: {
      moduleName: '参与大赛',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: Article,
    meta: {
      moduleName: '详情页',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/participate/sponsor',
    component: Sponsor,
    meta: {
      moduleName: '设奖单位',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/achievement',
    component: Achievement,
    meta: {
      moduleName: '大赛成果',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/achievement/article/:id',
    name: 'AchievementArticle',
    component: Article,
    meta: {
      moduleName: '大赛成果',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/news',
    component: News,
    redirect:to=>{
      return 'news/wjtz'
    },
    meta: {
      moduleName: '大赛资讯',
      title:'全国青少年科技创新大赛'
    },
    children:[
      {path:'wjtz',component:Wjtz,
      meta: {
        moduleName: '大赛资讯',
        title:'全国青少年科技创新大赛'
      },},
      {path:'hdbd',component:Hdbd, meta: {
        moduleName: '大赛资讯',
        title:'全国青少年科技创新大赛'
      },},
      {path:'mdgs',component:Mdgs,
      meta: {
        moduleName: '大赛资讯',
        title:'全国青少年科技创新大赛'
      },},
      {path:'hdyx',component:Hdyx,
       meta: {
        moduleName: '大赛资讯',
        title:'全国青少年科技创新大赛'
      },},
      {path:'wjzp',component:Wjzp, 
      meta: {
        moduleName: '大赛资讯',
        title:'全国青少年科技创新大赛'
      },},
      {path:'wjsp',component:Wjsp}
    ]
  },
 
  {
    path: '/qa',
    component: Qa,
    meta: {
      moduleName: '咨询答疑',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/news/article/:id',
    name: 'Article',
    component: Article,
    meta: {
      moduleName: '大赛资讯',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/news/video/:id',
    component: Video,
    meta: {
      moduleName: '大赛资讯',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/news/photo/:id',
    component: PhotoAlbumn,
    meta: {
      moduleName: '大赛资讯',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/about/article/dszc',
    component: Dszc,
    meta: {
      moduleName: '关于大赛',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/participate/gz/dszc',
    component: Dszc,
    meta: {
      moduleName: '参与大赛',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/about/article/jsgz',
    component: Jsgz,
    meta: {
      moduleName: '关于大赛',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/participate/gz/jsgz',
    component: Jsgz,
    meta: {
      moduleName: '参与大赛',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/participate/gz/kjfd',
    component: Kjfd,
    meta: {
      moduleName: '参与大赛',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/about/article/kjfd',
    component: Kjfd,
    meta: {
      moduleName: '关于大赛',
      title:'全国青少年科技创新大赛'
    },
  },
  {
    path: '/search',
    component: SearchResult,
    meta: {
      moduleName: '搜索结果页',
      title:'全国青少年科技创新大赛'
    },
  },
  
]
function setBeforeEnterFunc(routerArray, func) {
  routerArray.forEach(r => {
      r.beforeEnter = func;
      if (r.children) {
          setBeforeEnterFunc(r.children, func);
      }
  });
}
const RouterMap = routes
setBeforeEnterFunc(RouterMap, function (to, from, next) {
  if(to.meta && !to.meta.noTrace){
     MolaTrack(to.path,location.origin+'/#'+to.path,to.meta.title,to.meta.moduleName,'gxkjcxds');
     next();

  }
  

})

const router = new VueRouter({
  routes
})

export default router

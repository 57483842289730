<template>
  <div class="container">
    <div class="article">
      <h3 class="article-title">科技辅导员科技教育创新成果竞赛规则（2021年）</h3>
      <div class="detail">
        <p>
          本规则依据《全国青少年科技创新大赛章程》制定,适用于全国青少年科技创新大赛科技辅导员科技教育创新成果竞赛参赛者申报和竞赛评审工作。
        </p>

        <p><strong> 一、参赛人员和作品要求</strong></p>

        <p>（一）参赛人员</p>

        <p>
          1.参赛人员为中小学校科学教师、科技辅导员，各级教育研究机构、校外科技教育机构和活动场所的科技教育工作者（以下统称&ldquo;科技辅导员&rdquo;）。
        </p>

        <p>2.参加全国竞赛的科技辅导员须由省级组织单位在省赛获奖科技辅导员中按规定名额择优推荐。</p>

        <p>（二）参赛作品</p>

        <p>
          1.在同一届大赛中，每名参赛科技辅导员只能申报一项作品，只接受个人作品申报。参赛作品须在终评活动当年7月1日前两年内完成。
        </p>

        <p>2.作品分类：</p>

        <p>参赛作品分为科教制作类和科教方案类两类。</p>

        <p>
          （1）科教制作类作品是由科技辅导员本人设计或改进的为科技教育教学服务的教具、仪器、设备等。作品按学科分为物理教学类、化学教学类、生物教学类、数学教学类、信息技术教学类和其他。
        </p>

        <p>（2）科教方案类作品是由科技辅导员本人设计撰写的科技教育活动或教学的预设方案,须是已开始实施或已实施完成。</p>

        <p>3.不接受的作品申报</p>

        <p>（1）作品内容或研究过程违反国家法律、法规和社会公德或者妨害公共利益。</p>

        <p>（2）作品存在抄袭或侵犯他人知识产权等学术不端问题。</p>

        <p>（3）涉及食品技术、药品类的作品。</p>

        <p>（三）申报材料</p>

        <p>1.申报书：完整填写当届大赛发布的申报书。</p>

        <p>2.研究报告：必须是独立于申报书之外的书面报告。</p>

        <p>科教制作类报告须包含以下内容的文字介绍，并附实物照片或设计图等：</p>

        <p>（1）作品的教学用途与应用场景。</p>

        <p>（2）作品的科学原理和应用方法。</p>

        <p>（3）作品的改进点或创新点。</p>

        <p>（4）作品的其他介绍。</p>

        <p>科教方案类报告须包含以下内容的文字介绍：</p>

        <p>（1）方案的背景（需求分析）与目标。</p>

        <p>（2）方案所涉及的对象、人数。</p>

        <p>（3）方案的主体部分：</p>

        <p>a.活动内容、过程和步骤</p>

        <p>b.难点、重点、创新点</p>

        <p>c.利用的各类科技教育资源（场所、资料、器材等）</p>

        <p>d.活动中可能出现的问题及解决预案</p>

        <p>e.预期效果与呈现方式</p>

        <p>f.效果的评价标准与方式</p>

        <p>（4）活动已开始实施或已实施完成的证明材料。</p>

        <p><strong>二、评审</strong></p>

        <p>（一）评审标准</p>

        <p>1．科教制作类</p>

        <p>（1）思想性：作品及研制作品的过程体现出正确的价值观，遵守学术道德规范，符合科学伦理。</p>

        <p>（2）科学性：作品以先进的科学理论或事实作依据，研究方法正确，研制过程符合逻辑，比现有成品更趋合理。</p>

        <p>
          （3）创新性：解决了前人没有解决或没有完全解决的问题，与现有成品相比，或方法不同，或路线不同，在材料、工艺、手段等方面有显著进步。
        </p>

        <p>
          （4）实用性：与社会生产生活密切相关，有社会、经济效益或教育教学效果，在对青少年进行科学教育方面有显著进步，具有推广前景。
        </p>

        <p>2．科教方案类</p>

        <p>
          （1）科学性：方案所述概念和原理不违背自然科学、社会科学、思维科学、数学、技术和工程学等所涵盖的基本规律；符合科技教育活动的基本规律。
        </p>

        <p>
          （2）教育性：方案的活动目标明确，并与实现方法和手段相匹配；能激发青少年的科学兴趣、促进青少年主动学习，有利于青少年体验和理解科学、培养科学精神和创新能力；能让青少年有较大的思考和实践空间、经历科学探究的完整过程，能启发青少年对科技发展与人类生活、社会发展关系的思考。
        </p>

        <p>
          （3）创新性：方案体现先进的科技教育理念；内容、过程或方法设计有创意；教学或活动构思新颖、巧妙、独特；善于运用新技术手段。
        </p>

        <p>
          （4）可行性：符合方案所覆盖对象的知识、能力和认知水平；具备方案实施的必备条件；符合当地科技、教育、经济和社会发展水平，便于在科技教育教学活动中实施；不增加青少年的负担。
        </p>

        <p>
          （5）示范性：具有鲜明的时代特征，能体现当代科技发展方向和科技教育诉求；着重解决青少年现实生活中所面临的具体问题，便于推广普及；方案写作规范，逻辑清晰，重点难点表述清楚。
        </p>

        <p>
          （6）完整性：活动过程连续、完整；实施步骤、阶段清晰、明确；对实施过程中可能出现的困难及问题有预估和解决措施。
        </p>

        <p>（二）评审程序</p>

        <p>1．资格审查</p>

        <p>包括形式审查和学术审查两部分。</p>

        <p>（1）形式审查：如发现申报材料存在问题或缺失，申报者可在组委会规定的修改时间内对申报材料进行修改和补充。</p>

        <p>
          （2）学术审查：如发现参赛者存在违反科研诚信和行为规范问题，经全国大赛科学道德和伦理审查委员会审议通过，取消相关人员参赛资格。
        </p>

        <p>2．初评</p>

        <p>
          通过资格审查的作品进入初评。初评为网络评审，由全国评审委员会负责。初评阶段评选出约200项作品入围终评，其中科教制作类和科教方案类各约100项。
        </p>

        <p>3．终评</p>

        <p>
          终评阶段，评选产生当届创新大赛等级奖、单项奖和专项奖。终评评审采取现场问辩的形式。入围终评的参赛者须由本人参加终评现场问辩活动。因故不能参加现场问辩活动，视为自动放弃参赛资格，由此产生的名额空缺，不予递补。
        </p>

        <p>
          参赛科技辅导员在终评期间应严格遵守大赛组委会的各项组织纪律和赛程安排。如出现违纪行为，经大赛监督委员会和评审评委会研究，视具体情况核减作品得分。
        </p>

        <p><strong> 三、表彰奖励</strong></p>

        <p>
          科技辅导员科技教育创新成果奖项分等级奖和专项奖。入围终评作品的等级奖获奖比例约为80%，其中，一等奖15%、二等奖35%、三等奖50%，按照参赛作品分类颁发证书和奖牌，由主办单位进行表彰。专项奖由设奖单位进行表彰，颁发证书、奖金或奖品等。
        </p>

        <p><strong> 四、&ldquo;十佳优秀科技辅导员&rdquo;评选</strong></p>

        <p>&ldquo;十佳优秀科技辅导员&rdquo;评选是针对科技辅导员工作业绩和综合能力的评审。</p>

        <p>（一）申报条件</p>

        <p>1．热爱青少年科技教育事业，对青少年科技教育有正确的理念和认识。</p>

        <p>
          2．从事科技辅导员或相关工作满5年以上。具备较高的科技教育理论水平和丰富的组织开展青少年科技活动的经验，并取得优异成绩。
        </p>

        <p>3．须有作品参加当届科技教育创新成果竞赛，且在历届创新大赛中未获得过&ldquo;十佳优秀科技辅导员&rdquo;奖项。</p>

        <p>4．须有作为区、县级以上青少年科技辅导员培训活动主讲教师的经历。</p>

        <p>（二）申报要求</p>

        <p>1．科技辅导员在参加科技教育创新成果竞赛的同时，可自愿申请参加&ldquo;十佳优秀科技辅导员&rdquo;评选。</p>

        <p>
          2．申报书中须如实填写本人简历、获得过的奖励、发表的论文或著作、作为主讲教师参与科技辅导员培训工作等个人业绩，并将证明个人业绩的相关材料（如获奖证书、发表的论文或著作、培训邀请函、会议手册等）复印件带至终评现场。
        </p>

        <p>
          3．申报者所在单位须审查申报书内容是否属实、是否同意推荐其参加&ldquo;十佳优秀科技辅导员&rdquo;评选活动，并加盖公章。
        </p>

        <p>（三）评选程序</p>

        <p>
          在科技教育创新成果竞赛成绩基础上，结合科技辅导员的个人业绩、综合素质测评笔试、现场问辩等进行综合评分，评选出10名优秀科技辅导员。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  .article {
    width: 1200px;
    margin: auto;
    text-align: center;
    font-size: 19px;
    color: #333;
    margin-bottom: 25px;
    line-height: 2;
    padding: 40px 0;

    &-title {
      margin-bottom: 15px;
    }
  }
  .detail {
    font-size: 15px;
  }
  p {
    text-align: justify;
    text-indent: 2em;
    margin-bottom: 10px;
    strong {
      font-weight: bold;
    }
  }
}
@media (max-width: 768px) {
  .container {
    width: 100%;
    .article {
      width: 6.9rem;
      font-size: var(--font-size-xl-m);
      margin-bottom: 0.2rem;
    padding: 0.4rem 0;

      &-title {
        margin-bottom: 0.15rem;
      }
    }
    .detail {
      font-size: var(--font-size-l-m);
    }
    p {
      margin-bottom: 0.1rem;
    }
  }
}
</style>>

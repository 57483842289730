import axios from 'axios'
const request = axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
  timeout: 5000 // request timeout
})

request.interceptors.response.use(response => {
  const {data} = response
  //   要根据success的成功与否决定下面的操作
  if (data.success) {
    return data
  } else {
    return Promise.reject(new Error(message))
  }
}, error => {
  Message.error(error.message) 
  return Promise.reject(error) 
})


export default request
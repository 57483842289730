<template>
  <div class="page">
    <div class="banner-top"></div>

    <div class="title title-sqsj"></div>
    <div class="main-width-container">
      <div class="intro-container">
        <p>
          全国青少年科技创新大赛由中国科协、自然科学基金委、共青团中央、全国妇联共同主办的一项全国性的青少年科技竞赛活动。大赛具有广泛的活动基础，从基层学校到全国大赛，每年约有1000万名青少年参加不同层次的活动。为提高全社会对青少年科技教育和科技创新后备人才培养事业的关注，大赛支持和鼓励社会机构在大赛上设立专项奖，奖励在不同学科领域中有一定研究成果，展现出科研潜力的优秀青少年和在科技教育领域有突出成绩的教育工作者。每年创新大赛期间举办专项奖晚会，邀请设奖单位嘉宾参加并为获奖学生和科技教师颁奖。
        </p>
        <div class="sponsor-qa">
          <div class="btn-qa" @click="activePopup = 'sjtj'">设奖条件</div>
          <div class="btn-qa" @click="activePopup = 'sjqy'">设奖权益</div>
          <div class="btn-qa" @click="activePopup = 'sjcx'">设奖程序</div>
        </div>
        <p>关于大赛设立专项奖，如有问题，请发邮件至：<a href="mailto:zhaoming@cast.org.cn">zhaoming@cast.org.cn</a></p>
      </div>
    </div>

    <div class="block-sjdw">
      <div class="main-width-container">
        <div class="title title-sjdw"></div>
        <div class="timeline-container">
          <swiper :options="swiperOptionYear" class="year-swiper" ref="swiper">
            <!-- slides -->
            <swiper-slide v-for="item in sponsorList" :key="item.year">
              <div class="year">{{ item.year }}</div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>

        <Sjdw :sponsorData="currentSponsorList" />

        <div class="title title-bjxc"></div>
        <DataList
          :dataList="awardList"
          showType="CardList"
          linkPattern="/participate/article/{id}"
          :pageSize="size"
          :totalCount="awardCount"
          :hasPagination="true"
          :showDate="false"
          :showView="false"
          :page="page"
          @handlePageChange="handlePageChange"
        />
      </div>
    </div>

    <!-- <div class="overlay-dark" v-show="activePopup">
      <div class="popup">
        <div class="popup-title">{{ activePopup ? activePopupData.title : '' }}</div>
        <div class="content" v-html="activePopup ? activePopupData.content : ''"></div>
        <div class="btn-close" @click="activePopup = null"></div>
        <div class="btn-ok" @click="activePopup = null">确定</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { sponsorRuleData } from '../../../public/testData/testDataParticipate.js'
import Sjdw from './Sjdw.vue'
import DataList from '../../components/DataList/DataList'
import { getMoreCompany, getAwardList } from '../../api/competition'
export default {

  data () {
    const _this = this
    return {
      sponsorList: [],
      page: 1,
      size: 4,
      awardCount: 0,
      currentSponsorList: [],
      currentYear: '',
      awardList: [],
      sponsorRuleData,
      currentYearIndex: 0,
      swiperOptionYear: {
        autoplay: false,
        slidesPerView: 5,
        centeredSlides: true,
        loop: false,
        slideToClickedSlide: true,
        observer: true,
        navigation: {
          nextEl: '.timeline-container .swiper-button-next',
          prevEl: '.timeline-container .swiper-button-prev',

        },
        on: {
          init: function () {
            if (_this.currentYearIndex > 0) {
              this.slideTo(_this.currentYearIndex, 0)
            }
          },
          slideChange: function () {
            _this.initDataByYearIndex(this.activeIndex)
          }
        }
      },
      // 当前激活的弹窗。当前有：设奖条件sjtj、设奖权益sjqy、设奖程序sjcx三个。
      activePopup: null

    }
  },
  computed: {
    activePopupData () {
      return this.sponsorRuleData.data[this.activePopup]
    },

  },
  components: {
    DataList, Sjdw
  },
  methods: {
    // 根据年份初始化设奖单位与颁奖现场
    initDataByYearIndex (yearIndex) {
      this.currentYearIndex = yearIndex
      this.currentSponsorList = this.sponsorList[this.currentYearIndex].list
      this.currentYear = this.sponsorList[this.currentYearIndex].year
      this.page = 1
      this.getAwardLiveList()
    },
    // 获取设奖单位
    async getCompanyByYear () {
      const res = await getMoreCompany()
      this.sponsorList = res.data.reverse()

      if (this.sponsorList.length === 1) {
        this.$refs.swiper.swiper.slideTo(1, 0)
      }else{
      this.$refs.swiper.swiper.slideTo(this.sponsorList.length - 1, 0)
      }






    },
    // 按年份获取颁奖现场
    async getAwardLiveList () {
      const res = await getAwardList(this.page, this.size, this.currentYear)
      this.awardList = res.data.list
      this.awardCount = res.data.count
    },
    // 颁奖现场列表分页
    handlePageChange (val) {
      this.page = val
      this.getAwardLiveList()
    }
  },
  mounted () {
    this.getCompanyByYear()
  }
}
</script>


<style scoped lang="less">
.page {
  .banner-top {
    height: 400px;
    background-image: url('../../assets/images/participate/banner-sponsor.jpg');
    background-repeat: no-repeat;
    background-size: 1920px 400px;
    background-position: center;
  }
  .title-sqsj {
    background-image: url('../../assets/images/participate/title-sqsj.png');
  }
  .title-sjdw {
    background-image: url('../../assets/images/participate/title-sjdw.png');
  }
  .title-bjxc {
    background-image: url('../../assets/images/participate/title-bjxc.png');
  
  }
  .intro-container {
    color: #666;
    line-height: 2.1;
    text-indent: 2em;
    margin-bottom: 80px;
    a {
      color: var(--main-color-1);
      margin: 0 10px;
    }
    .sponsor-qa {
      text-align: center;
      text-indent: 0;
      .btn-qa {
        background-image: url('../../assets/images/participate/bg-text.png');
        background-size: 100% 100%;
        display: inline-block;
        width: 231px;
        height: 232px;
        font-size: var(--font-size-xl);
        line-height: 232px;
        letter-spacing: 2px;
        cursor: pointer;
        margin: 50px;
      }
    }
  }

  .block-sjdw {
    &::before {
      content: '';
      display: table;
    }
    background-image: url('../../assets/images/participate/bg-sjdw.jpg');
    background-color: #fff;
    background-size: 1920px auto;
    background-repeat: no-repeat;
    background-position: top center;
    min-height: 985px;
    margin-bottom: -300px;
    padding-bottom: 300px;
  }
  .timeline-container {
    background-image: linear-gradient(90deg, rgba(230, 0, 18, 0), rgba(230, 0, 18, 0.99), rgba(230, 0, 18, 0));
    background-size: 900px 6px;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    .swiper-button-next,
    .swiper-button-prev {
      width: 24px;
      height: 42px;
      margin-top: -21px;
    }
    .swiper-button-next {
      background-image: url('../../assets/images/arrow-right-thin.png');
    }
    .swiper-button-prev {
      background-image: url('../../assets/images/arrow-left-thin.png');
    }
  }
  .year-swiper {
    width: 800px;
    margin: 0 auto;

    .swiper-slide {
      user-select: none;
      cursor: pointer;
      font-size: 28px;
      text-align: center;
      color: #999999;
      height: 120px;
      &.swiper-slide-active {
        overflow-y: visible;
        color: #e60012;
        font-size: 35px;
        transform: scale(1.3);
      }
      .year {
        padding-top: 7px;
        height: 120px;
        line-height: 48px;
        position: relative;
        transition: all 0.2s;
      }
      &::after {
        content: ' ';
        width: 18px;
        height: 18px;
        border: 4px solid #fff;
        box-sizing: border-box;
        border-radius: 50%;
        background: #1262a7;
        position: absolute;
        top: 50%;
        margin-top: -9px;
        left: 50%;
        margin-left: -9px;
      }
    }
  }

  /deep/.swiper-button-next,
  /deep/.swiper-button-prev {
    width: 40px;
    height: 54px;
    margin-top: -27px;
    background-size: 100% 100%;
    pointer-events: initial !important;
  }
  /deep/.swiper-button-next {
    background-image: url('../../assets/images/arrow-right.png');
    right: 0;
  }
  /deep/.swiper-button-prev {
    background-image: url('../../assets/images/arrow-left.png');
    left: 0;
  }

  /deep/.swiper-button-next,
  /deep/.swiper-button-prev {
    width: 40px;
    height: 54px;
    margin-top: -27px;
    background-size: 100% 100%;
  }
  .logo-swiper {
    height: 275px;
  }

  /deep/.card-simple {
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    height: 230px;
  }

  .overlay-dark {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.45);

    .popup {
      background-image: url('../../assets/images/bg-popup.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom center;
      width: 850px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      border-radius: 20px;
      overflow: hidden;
      padding: 20px;

      .popup-title {
        font-size: var(--font-size-l);

        background-image: url('../../assets/images/border-line-blue-red.png');
        background-size: 100% 2px;
        background-repeat: no-repeat;
        background-position: bottom center;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
      .content {
        line-height: 2;
        color: #666;
        margin-bottom: 20px;
        text-indent: 2em;
      }
      .btn-close {
        position: absolute;
        background-image: url('../../assets/images/btn-close.png');
        width: 40px;
        height: 40px;
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-position: center;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
      .btn-ok {
        width: 224px;
        line-height: 49px;
        margin: 0 auto;
        text-align: center;
        font-size: var(--font-size-l);
        color: #fff;
        letter-spacing: 10px;
        background: #3a93ff;
        border-radius: 25px;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 768px) {
  .page {
    .banner-top {
      height: 2.68rem;
      background-size: 12.864rem 2.68rem;
    }
    .title-sqsj {
      background-image: url('../../assets/images/participate/title-sqsj-m.png');
      height: .87rem;
    }
    .title-sjdw {
      background-image: url('../../assets/images/participate/title-sjdw-m.png');
      height: .78rem;
    }
    .title-bjxc {
      background-image: url('../../assets/images/participate/title-bjxc-m.png');
      height: .78rem;
    }
    .intro-container {
      margin-bottom: 0.5rem;
      a {
        margin: 0 0.2rem;
      }
      .sponsor-qa {
        white-space: nowrap;
        .btn-qa {
          width: 1.85rem;
          height: 1.85rem;
          font-size: var(--font-size-l-m);
          line-height: 1.85rem;
          letter-spacing: 0.01rem;
          margin: 0.5rem 0.2rem;
        }
      }
    }

    .block-sjdw {
      min-height: auto;
      margin-bottom: -4rem;
      padding-bottom: 4rem;

      .nav {
        position: relative;
        z-index: 2;
      }
    }
    .timeline-container {
      background-size: 6.32rem 0.068rem;
      .swiper-button-next,
      .swiper-button-prev {
        width: 0.259rem;
        height: 0.476rem;
        margin-top: -0.238rem;
      }
    }
    .year-swiper {
      width: 6rem;
      .swiper-slide {
        font-size: var(--font-size-l-m);

        height: 2rem;
        &.swiper-slide-active {
          font-size: 0.4rem;
        }
        .year {
          padding-top: 0.2rem;
          height: 2rem;
          line-height: 0.85rem;
        }
        &::after {
          content: ' ';
          width: 0.28rem;
          height: 0.28rem;
          border: 0.05rem solid #fff;

          margin-top: -0.14rem;

          margin-left: -0.14rem;
        }
      }
    }

    /deep/.swiper-button-next,
    /deep/.swiper-button-prev {
      width: 0.4rem;
      height: 0.54rem;
      margin-top: -0.27rem;
      background-size: 100% 100%;
    }

    .logo-swiper {
      height: unset;
      .swiper-container {
        background: red;
        margin: 0 0.5rem;
      }
    }

    /deep/.card-container {
      margin: 0 auto !important;
    }
    /deep/.card-simple {
      background: transparent;
      box-shadow: none;
      border-radius: 0;
      height: 3rem;
    }

    .overlay-dark {
      position: fixed;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.45);

      .popup {
        background-image: url('../../assets/images/bg-popup.jpg');
        width: 6.5rem;
        border-radius: 0.2rem;
        overflow: hidden;
        padding: 0.3rem;

        .popup-title {
          font-size: var(--font-size-l);

          background-image: url('../../assets/images/border-line-blue-red.png');
          background-size: 100% 2px;
          background-repeat: no-repeat;
          background-position: bottom center;
          margin-bottom: 10px;
          padding-bottom: 10px;
        }
        .content {
          line-height: 2;
          color: #666;
          margin-bottom: 20px;
          text-indent: 2em;
        }
        .btn-close {
          position: absolute;
          background-image: url('../../assets/images/btn-close.png');
          width: 40px;
          height: 40px;
          background-size: 20px 20px;
          background-repeat: no-repeat;
          background-position: center;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }
        .btn-ok {
          width: 224px;
          line-height: 49px;
          margin: 0 auto;
          text-align: center;
          font-size: var(--font-size-l);
          color: #fff;
          letter-spacing: 10px;
          background: #3a93ff;
          border-radius: 25px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
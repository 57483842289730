<template>
  <div class="footer">
    <div class="main-width-container">
      <div class="footer-logo">
        <a href="https://cyscc.org/" target="_blank"
          ><img src="@/assets/images/logo-kczm-2.png" alt=""
        /></a>
      </div>
      <div class="footer-info">
        <div class="text">
          <p>主办单位：中国科协、自然科学基金委、共青团中央、全国妇联</p>
          <p>
            <span>京ICP备12019928号-2</span>
            <img src="@/assets/images/footer/zs.jpg" alt="" />
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">





 .footer {
    background: url("@/assets/images/footer/bottom.png") top center/cover no-repeat;
    height:204px;
    min-width:1200px;
    font-size: var(--font-size-s);
     .footer-logo {
        width:305px;
        height:95px;
        img {
          width: 100%;
        }
      }
      .footer-info {
    
        
        display: flex;
        justify-content: flex-end;
        
        .text {
          color: #fff;
          line-height: 32px;
          text-align: right;
          & p:last-of-type {
            img {
              width: 98px;
              height: 30px;
              margin-left: 15px;
            }
          }
        }
        .qrwx,
        .qrwb {
          font-size: 12px;
          line-height: 1.6;
          text-align:center;
          img {
            width: 84px;
            height: 84px;
          }
        }
        .qrwx {
          margin-left: 45px;
        }
        .qrwb {
          margin-left: 20px;
        }
      }
  }
@media (max-width: 768px) {
 .footer {
  background: url("../assets/images/footer/bottom-m.png") center center/cover no-repeat;
  height:3.12rem;
  min-width: auto;
  padding: 0 0.34rem 0.5rem;
 .footer-logo {
      width: 2.07rem;
      height: 0.64rem;
      margin-left: 0.3rem;
    
     
    }
    .footer-info {
      
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 0.71rem;
      font-size: var(--font-size-m-m);
      .text {
        width: 100%;
        line-height: 0.44rem;
        margin-bottom: 0.2rem;
        text-align:left;
        & p:last-of-type {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          img {
            margin-left: 0.18rem;
            width: 0.98rem;
            height: 0.3rem;
          }
        }
      }
      .qrwx,
      .qrwb {
        margin-left:0;
        font-size: var(--font-size-s-m);
     
        img {
          width: 1.68rem;
          height: 1.68rem;
          
        }
      }
      .qrwb {
        margin-left: 0.41rem;
      }
    }
}
}
</style>

<template>
  <div class="container">
    <div class="article">
      <h3 class="article-title">青少年科技创新成果竞赛规则（2021年）</h3>
      <div class="detail">
        <p>
          本规则依据《全国青少年科技创新大赛章程》制定,适用于全国青少年科技创新大赛青少年科技创新成果竞赛参赛者申报和竞赛评审工作。全国和地方竞赛应遵循本规则参赛及开展组织工作。
        </p>

        <p><strong>一、申报</strong></p>

        <p>（一）申报者和申报作品要求</p>

        <p>
          1．参赛学生须为国内在校中小学生（包括普通中小学、中等职业学校、特殊教育学校、国际学校）。每个参赛学生（包括集体作品的学生）在一届大赛中，只能申报一个作品参加科技创新成果竞赛。
        </p>

        <p>2．参加全国竞赛学生须由省级组织单位在省赛获奖学生中按规定名额择优推荐，须符合全国竞赛规则和各项申报要求。</p>

        <p>3．参赛者须承担申报作品全部或主体研究工作。小学生作品选题原则上需与日常生活相关。</p>

        <p>4．参赛作品须在终评活动当年7月1日前两年内完成。</p>

        <p>5．集体作品要求：</p>

        <p>
          （1）集体作品的申报者不得超过3人，并且必须是同一地区（指同一城市或县域）、同一学段（小学、初中、高中或中专）的学生合作作品。
        </p>

        <p>
          （2）集体作品不能在研究过程及参赛中途加入新成员。每名成员都须全面参与、熟悉作品各项工作，合作、分担研究任务，提交的研究成果应为所有成员共同完成。
        </p>

        <p>
          （3）集体作品在申报时，所有成员的信息资料均应在申报表中填写，并在研究报告中说明每名成员的分工和完成的主要任务。
        </p>

        <p>（4）同一竞赛周期内，集体作品和个人作品不能进行相互转换。</p>

        <p>
          6．作品分类：按照创意来源和专业程度，参赛作品分为A、B两类：A类作品指选题专业性较强，且需具备较为深厚的专业基础，并在专业实验室或专业机构完成的作品；B类作品指选题源于日常生活，能够为经济社会发展或社会生活带来便利的小发明、小制作、小论文等。小学生原则上只能申报B类作品，如申报A类作品，将按中学生评审标准参赛。
        </p>

        <p>7.参加过往届创新大赛的作品，如再次以同一选题参赛，须以新的研究成果申报且研究时间持续一年以上。</p>

        <p>
          8．每项参赛作品可有1-3名指导教师，对学生开展研究给予辅助性指导。指导教师应了解并遵守竞赛规则，在申报时签署诚信承诺书，对学生参赛作品的真实性、研究过程的科学性及学生遵守科技实践活动行为规范的情况负责。如指导教师与参赛学生有亲属关系，应在申报时如实填写。
        </p>

        <p>
          9．参赛学生开展涉及脊椎动物实验或有潜在危险的病原体、生物制剂、化学制剂、有毒有害物质、放射性原材料等相关研究，须符合相关实验操作规程，并在专业人员指导下完成。
        </p>

        <p>
          10．参赛学生在开展研究的各阶段应自觉遵守科学研究的道德规范和行为准则，尊重他人知识产权。参赛作品应反映申报者本人的研究工作，对于指导教师或他人协助完成的内容要进行明确说明。
        </p>

        <p>（二）不接受的申报</p>

        <p>1．作品内容或研究过程违反国家法律、法规和社会公德或者妨害公共利益。</p>

        <p>2．研究内容不利于中小学生心理或生理健康发展。</p>

        <p>3．作品存在抄袭、成人代做或侵犯他人知识产权等学术不端问题。</p>

        <p>
          4．小学生作品出现伤害或处死实验动物、涉及有风险的动物、植物、微生物、病原体、离体组织、器官、血液、体液，以及有毒有害的生物制剂、化学制剂、放射性原材料等物质的相关研究。
        </p>

        <p>
          5.中学生作品涉及脊椎动物实验或有潜在危险的病原体、生物制剂、化学制剂、有毒有害物质、放射性原材料等相关研究，不符合相关实验操作规程，未在专业人员指导下完成。
        </p>

        <p>6．其他不符合申报作品要求（参见申报者和申报作品要求）的作品。</p>

        <p>（三）学科分类</p>

        <p>1.小学生作品</p>

        <p>（1）物质科学：研究、发现生活中的物质及其运动、变化的规律。</p>

        <p>（2）生命科学：观察、研究自然界的生命现象、特征和发生、发展规律，各种生物之间及生物与环境之间相互关系。</p>

        <p>（3）地球环境与宇宙科学：研究地球与宇宙中有关现象，人类与地球环境、地球与宇宙的关系等。</p>

        <p>（4）技术：将科学、技术应用于日常生活，综合设计或开发制作以解决实际问题。</p>

        <p>
          （5）行为与社会科学：通过观察、实验和调查的方法研究人或动物的行为与反应，人类社会中的个人之间、个人与社会之间的关系。
        </p>

        <p>2.中学生作品</p>

        <p>（1）数学：代数、几何、概率、统计等数学领域的基础研究和相关应用。</p>

        <p>（2）物理与天文学：力学、电磁学、光学、热学等物理学科及天文学科相关领域的研究和应用。</p>

        <p>（3）化学：无机化学、有机化学、物理化学、分析化学等相关领域的研究和应用。</p>

        <p>（4）生命科学：动物学、植物学等生命科学相关领域的实验研究或理论分析。</p>

        <p>（5）计算机科学与信息技术：与计算机科学与技术相关的理论研究和技术探索。</p>

        <p>（6）工程学：机械、电路等工程技术领域相关研究和应用。</p>

        <p>（7）环境科学：水土保护、气候变化、生态保护等环境学科相关领域的研究和应用。</p>

        <p>（8）行为和社会科学：针对特定社会现象、事件或问题开展的调查和研究。</p>

        <p>（四）申报材料</p>

        <p>1. 申报书：完整填写当届大赛申报书。</p>

        <p>
          2．查新报告：每名申报者应在作品研究开始前和申报参赛前对作品选题和研究内容进行查新检索，并至少提交1份真实、规范的查新报告。
        </p>

        <p>
          3.研究报告：研究报告应包括标题、摘要、关键词、正文（包括研究背景、研究目的、研究内容、研究方法、实验过程和结果、分析和讨论、研究结论等）及参考文献。研究报告中凡引用他人已公开发表的研究方法、数据、观点、结论或成果等，必须规范引用，并在参考文献中列出；凡涉及他人协助完成的研究工作内容和相关成果，必须明确说明。
        </p>

        <p>
          4．作品附件：附件中须提交完整、真实的原始实验记录、研究日志等相关材料，用于证明学生的研究过程和对主要创新点的贡献。附件可适量提交研究作品相关的辅助图片，如作品中有实物模型，则需提交时长不超过1分钟的视频资料，用于证明和演示实物模型的功能和创新点。入围终评的作品，必须同时在终评问辩现场向评委提供所有原始实验记录、研究日志等相关材料，并现场展示研究报告中提到的主要创新点。
        </p>

        <p>
          5．诚信承诺书：参赛学生、指导教师须签订科研诚信承诺书，承诺研究过程和成果取得符合科研诚信和学术规范，并分别在指定位置签字确认，加盖所在学校公章。
        </p>

        <p>6．证明材料：作品涉及下列内容的还须提供有关部门的证明材料。</p>

        <p>
          （1）依托专业研究机构或实验室开展研究的，需在实验开始前获得该机构或实验室主管部门/单位的许可，并在申报时提供确认或批准依据。
        </p>

        <p>（2）医疗保健用品，由省级以上相关医疗科研部门开具临床使用鉴定。</p>

        <p>（3）动物、植物新品种，由省级以上农科部门开具证明，证明确为培育和发现的新品种。</p>

        <p>（4）国家保护的动、植物，由省级以上林业等管理部门开具证明，证明作品在研究过程没有对动、植物造成损害。</p>

        <p><strong>二、评审</strong></p>

        <p>（一）评审标准</p>

        <p>
          评审重点考察参赛学生的科研潜质和创新素养。组委会将组织全国高等院校、科研院所的学科专家组成评审委员会，按照以下维度评审。
        </p>

        <p>
          1．科研潜质：参赛学生对科学具有浓厚的兴趣，对本人研究的成果具有强烈的分享意愿，具有一定的科学素养和严谨的科学态度；学生对于科学研究工作的基本规律和方法有一定理解，基础科学理论和知识掌握扎实、运用准确。
        </p>

        <p>
          2．作品选题：作品选题符合青少年认知能力和成长特点，研究方法和研究技术合理可行，实验材料和仪器设备能够合规获取和使用。
        </p>

        <p>3．作品水平</p>

        <p>
          （1）创新性：作品的立意、提出的观点以及研究的方法等方面有新意、有创见。分析问题、实验设计、技术路线、数据处理方法独特。
        </p>

        <p>（2）科学性：作品符合客观科学规律，立论明确，论据充分；研究方法和技术方案合理。</p>

        <p>
          （3）完整性：作品已取得阶段性研究成果；有足够的科学研究工作量(调查、实验、制作、求证等)；原始实验数据和研究日志等记录规范、资料齐全，研究和分析数据充分，有说服力。
        </p>

        <p>（4）实用性：作品成果能够进行实际应用，能够对经济社会发展或生产生活产生积极影响。</p>

        <p>
          4．研究过程：学生具备开展研究的基本素质和能力；能够理解作品相关的基本科学原理和概念，掌握或了解涉及的研究方法和关键技术。学生是作品创新点提出、实施和验证的主要贡献者，对研究核心问题的理解和回答清晰准确；能够意识到研究的不足之处和局限性。
        </p>

        <p>
          5．现场表现：学生现场问答逻辑清晰、语言得当；作品展示结构合理、条理清晰；展板内容齐全,设计新颖别致,有一定制作工作量；展示资料齐全，作品展示效果好。
        </p>

        <p>
          6．小学生作品重点考查：作品选题是否符合选手年龄段的思维方式、知识结构和实施能力；对于调查、实验、制作、求证等科学探究方法的应用；收集和获取证据、整理信息、分析数据、得出结论的能力；作品是否有阶段性研究成果。
        </p>

        <p>
          7．集体作品考察团队合作情况，团队成员分工合理，每个成员均对作品的完成有实质贡献；作品成果是所有成员共同努力的结果。
        </p>

        <p>（二）评审程序</p>

        <p>1.资格审查：包括形式审查和学术审查两部分。</p>

        <p>（1）形式审查：如发现申报材料存在问题或缺失，申报者可在组委会规定的修改时间内对申报材料进行修改和补充。</p>

        <p>
          （2）学术审查：如发现参赛者存在违反科研诚信和行为规范问题，经全国大赛科学道德和伦理审查委员会审议通过，取消相关人员参赛资格。
        </p>

        <p>
          2．初评：通过资格审查的作品进入初评。初评为网络评审，由全国评审委员会负责。初评阶段评选约80%青少年参赛作品入围终评。
        </p>

        <p>3．终评：</p>

        <p>（1）等级奖评审程序和命题原则：</p>

        <p>
          大赛组委会选聘高等院校、科研院所的学科专家组成终评评审委员会，以多环节、多元化命题评价方式对参赛学生进行综合评价，并按照A、B类分别评选产生大赛各奖项。
        </p>

        <p>
          终评评审主要包括基于量表或任务的科研潜质测评、基于多对多交流的综合素质考察和基于参赛作品问辩的创新素养考察三个环节。
        </p>

        <p>
          入围终评的作品须申报者本人参加终评评审活动，如未参加终评将视为自动放弃参赛资格，由此产生的名额空缺不予递补。
        </p>

        <p>（2）专项奖评审：由设奖单位评选，专项奖评审原则不得与大赛评审原则相悖。</p>

        <p><strong> 三、终评展示和交流活动</strong></p>

        <p>1．参赛学生需参加大赛终评展示期间组织的公开展示、公众讲解和学生交流等活动。</p>

        <p>2．作品展示按学科分区，由组委会提供展区的基本展板、展台、电源和简单工具。</p>

        <p>3．参赛学生负责展示材料的设计制作、安装布设和保管维护；涉及实物的研究作品，须带到现场展示。</p>

        <p>
          4．每个作品应制作展板一块（高1.2米、宽0.9米）。参展实物宽不超过1.5米，高不超过2米，重量不超过100千克。作品展示材料中不能有易燃、易爆危险品和管制刀具；展品用电电压不得超过220伏。
        </p>

        <p>
          5．作品的展示材料中不得出现指导教师姓名、专家评价、媒体报道材料、以往获奖情况、正在申请或已获得专利情况等信息，不得出现涉嫌侵犯知识产权和个人隐私权的内容。
        </p>

        <p>6．作品布展完毕后需要接受组委会的检查，包括展板、展品、展示内容，检查合格才能进入评审程序。</p>

        <p><strong> 四、表彰奖励</strong></p>

        <p>
          青少年科技创新成果奖项分等级奖和专项奖。入围终评作品的等级奖获奖比例约为80%，其中，一等奖15%、二等奖35%、三等奖50%，按照参赛作品分类颁发证书和奖牌，由主办单位进行表彰。专项奖由设奖单位进行表彰，颁发证书、奖金或奖品等。
        </p>

        <p>五、监督和违规处理</p>

        <p>1．大赛设立评审监督委员会，由专家和主办单位代表组成，对竞赛评审工作进行监督，对涉嫌违规问题进行核查。</p>

        <p>
          2．大赛设立科学道德和伦理审查委员会，由科研机构学科专家、教育专家和一线教育工作者组成，对申报作品研究过程是否遵守科学道德和研究规范等进行审查。经审查，存在学术不端或违规情况的作品将取消参赛或获奖资格。
        </p>

        <p>
          3．申报、审查和初评阶段，如出现对参赛作品的投诉且经调查发现参赛作品存在抄袭、研究工作作弊、违反科研规范等问题，将取消作者参赛资格。
        </p>

        <p>
          4．终评阶段，如发现参赛作品存在抄袭、研究工作作弊等违反规则情况，将取消作者获奖资格；如终评评奖比例内，作品实际水平或作者答辩情况不符合获奖标准，经评审委员会表决，可不授予竞赛奖项。
        </p>

        <p>
          5．入围终评和获奖作品名单在竞赛网站进行公示，任何单位或个人如有异议，可向组委会秘书处进行实名投诉，并提供相关证据及联系方式。组委会将组织开展调查，并按照《章程》规定对相关部门和个人进行处理。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  .article {
    width: 1200px;
    margin: auto;
    text-align: center;
    font-size: 19px;
    color: #333;
    margin-bottom: 25px;
    line-height: 2;
    padding: 40px 0;

    &-title {
      margin-bottom: 15px;
    }
  }
  .detail {
    font-size: 15px;
  }
  p {
    text-align: justify;
    text-indent: 2em;
    margin-bottom: 10px;
    strong {
      font-weight: bold;
    }
  }
}
@media (max-width: 768px) {
  .container {
    width: 100%;
    .article {
      width: 6.9rem;
      font-size: var(--font-size-xl-m);
      margin-bottom: 0.2rem;
      padding: 0.4rem 0;

      &-title {
        margin-bottom: 0.15rem;
      }
    }
    .detail {
      font-size: var(--font-size-l-m);
    }
    p {
      margin-bottom: 0.1rem;
    }
  }
}
</style>>

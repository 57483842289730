<template>
  <div class="container">
    <div class="article">
      <h3 class="article-title">全国青少年科技创新大赛章程（2021年修订）</h3>
      <div class="detail">
        <strong class="chapter">第一章&nbsp; 总&nbsp; 则</strong>

        <p>
          <strong> 第一条</strong>
          为规范全国青少年科技创新大赛（以下简称创新大赛，英文名称China Adolescents Science &amp; Technology Innovation
          Contest，缩写CASTIC）的组织与实施，根据教育部竞赛管理办法等相关规定，本着与大赛主办单位协商一致的原则，制定本章程。章程规定的各项原则和要求适用于各参赛代表和全国及地方竞赛活动的组织管理。
        </p>

        <p>
          <strong> 第二条</strong>
          创新大赛是一项中小学生科技创新后备人才选拔和科技教育成果展示与交流活动。
        </p>

        <p>
          <strong> 第三条</strong>
          创新大赛的宗旨是：激发广大青少年的科学兴趣和想象力，培养其科学思维、创新精神和实践能力；弘扬科学精神，培养青少年求真务实、勇于创新的思想品格，树立科技报国的远大理想；促进各地青少年科技创新活动的广泛开展和科技教育水平的不断提升；发现和培养一批具有科研潜质、创新精神和爱国情怀的青少年科技创新后备人才。
        </p>

        <p>
          <strong> 第四条</strong>
          创新大赛分为全国竞赛和地方竞赛。地方竞赛包括省级创新大赛及省级以下的竞赛活动。
        </p>

        <p>
          <strong> 第五条</strong>
          创新大赛的基本方式：中小学生和科技辅导员根据每年竞赛规则，申报相关作品参赛；聘请专家通过对参赛作品和参赛者的综合测评，评定出获奖者，给予表彰；组织参赛作品展示和交流活动。
        </p>

        <strong class="chapter">第二章&nbsp; 基本内容</strong>

        <p>
          <strong> 第六条</strong>
          创新大赛每学年举办一届。全国创新大赛终评活动举办地采取申办方式确定，由省级组织委员会在前一年向全国组织委员会提出申请，经全国组织委员会考察确定。
        </p>

        <p>
          <strong> 第七条</strong>
          创新大赛评选内容包括青少年科技创新作品、科技辅导员科技教育创新作品等，按相应规则组织评审和展示。终评活动期间开展一系列科学主题交流和体验活动。
        </p>

        <p>
          <strong> 第八条</strong>
          创新大赛奖项分为:主办单位和组委会设立的大赛奖项；社会相关机构设立的专项奖。
        </p>

        <strong class="chapter">第三章&nbsp; 组织机构及职责</strong>

        <p>
          <strong> 第九条</strong>
          创新大赛的主办单位是中国科协、自然科学基金委、共青团中央、全国妇联。主办单位的主要职责是：负责审定创新大赛章程，指导和推动全国各级竞赛活动的组织实施，对创新大赛获奖者进行联合表彰。承办地省级人民政府可作为当届大赛主办单位，指导和保障大赛终评活动的组织实施。
        </p>

        <p>
          <strong> 第十条</strong>
          每届创新大赛设立全国组织委员会，由主办单位、承办单位推荐的人选组成。全国组织委员会的主要职责是：审议、修订大赛章程和规则；选定当届大赛承办地；建立大赛组织工作领导机制，决议全国竞赛相关工作事项，指导、监督地方竞赛组织开展等。
        </p>

        <p>
          <strong> 第十一条</strong>
          全国组织委员会下设秘书处，设在中国科协青少年科技教育工作机构，负责按照大赛章程推动创新大赛日常工作的组织实施，并向全国组织委员会报告工作。
        </p>

        <p>
          <strong> 第十二条</strong>
          创新大赛的承办单位由主办单位授权确定，包括中国科协青少年科技教育工作机构、举办地省级科协等机构。承办单位的主要职责是：制定当届创新大赛组织实施工作方案并组建相关工作团队；推动各项筹备工作的具体落实，共同提供经费等支撑保障；全面负责创新大赛的组织协调、赛事服务、后勤保障等工作的具体实施。
        </p>

        <p>
          <strong> 第十三条</strong>
          创新大赛设立全国评审委员会，由主办单位聘请科研和教育领域的专家组成，负责在大赛章程和规则所规定的原则下制定评审办法，独立完成评审工作，并向全国组织委员会报告评审结果。评审委员会成员应严格遵守评审纪律，不受任何组织或个人的影响和干扰，公平、公正地完成评审工作。
        </p>

        <p>
          <strong> 第十四条</strong>
          创新大赛设立全国评审监督委员会，由专家和主办单位代表组成，负责制定评审纪律，对评审工作进行全程监督，对评审结果具有最终裁定权。在申报至表彰名单公示结束前，授权全国组织委员会秘书处接受对参赛作品资格及内容的质疑投诉，组织专家核查涉嫌违规的作品和问题，在必要时对被质疑作品的作者、指导教师及所属学校等进行质询。
        </p>

        <p>
          <strong> 第十五条</strong>
          创新大赛设立科学道德和伦理审查委员会，由科研机构学科专家、教育专家和一线教育工作者组成，负责在申报审查、评审及表彰名单公示期间，对参赛者在项目研究的全过程是否遵守科学研究的道德规范和行为准则等进行审查。在申报审查阶段，根据审查结果，科学道德和伦理审查委员会有权决定被质疑作品是否具备参赛资格。
        </p>

        <p>
          <strong> 第十六条</strong>
          省级竞赛应按照全国竞赛的章程和规则规范组织实施，明确日常工作的组织实施机构，建立评审、监督、科学道德和伦理审查机制和工作委员会，落实获奖名单公示制度，接受全国竞赛组织委员会的监督和检查。省级科协应会同其他主办单位及相关部门，建立规范、长效的工作机制和管理制度，做好属地内各级竞赛的组织、协调、监督及保障工作。
        </p>

        <p>
          <strong> 第十七条</strong>
          各级大赛评审委员会、监督委员会、科学道德和伦理审查委员会成员须严格遵守回避原则，凡涉及与参赛代表有亲属、辅导、咨询，以及其他可能影响评审公平公正情况的，不得参与评审、监督和审查工作。
        </p>

        <strong class="chapter">第四章&nbsp; 申报和评审</strong>

        <p>
          <strong> 第十八条</strong>
          国内在校中小学生均可申报作品参赛。中小学校科学教师、科技辅导员，各级教育研究机构、校外科技教育机构和活动场所的科技教育工作者均可申报科技辅导员科技教育创新作品。
        </p>

        <p>
          <strong> 第十九条</strong>
          从省级竞赛推荐参加全国竞赛的优秀获奖作品，须在省级竞赛网站进行不少于一周的公示，接受社会公众的监督。公示无异议后，方可推荐参加全国创新大赛。
        </p>

        <p>
          <strong> 第二十条</strong>
          参加全国创新大赛的申报者和申报作品应符合大赛规则限定的各项要求，按照规定的学科和作品分类进行申报。申报者、指导教师及所在学校须签订科研诚信承诺书，承诺申报作品符合科学道德和科研诚信规范，相关科研资源获取合规。
        </p>

        <p>
          <strong> 第二十一条</strong>
          创新大赛评审分为初评和终评，通过对参赛选手的科研潜质、创新素养、研究过程和作品水平的考察确定获奖名单。获奖名单于终评活动结束后进行不少于一周的公示，接受社会公众的监督。公示期内，对获奖名单有异议，可向全国组织委员会进行实名投诉。投诉者须提供相关证据，全国组织委员会依法保护投诉者信息。
        </p>

        <strong class="chapter">第五章&nbsp; 组织实施和管理</strong>

        <p>
          <strong> 第二十二条 </strong
          >各主办单位安排专人作为创新大赛联络员，负责日常沟通联络，及时将重要事项报告本单位相关部门和领导并协调办理相关事项。
        </p>

        <p><strong> 第二十三条 </strong>全国组织委员会秘书处负责推动创新大赛的组织实施和日常管理，主要包括：</p>

        <p>启动阶段：征求主办单位相关业务司局的意见后，以中国科协办公厅名义印发竞赛启动通知，启动竞赛活动。</p>

        <p>申报审查阶段：接收各地推荐的申报作品，根据科学道德和伦理审查委员会授权组织实施申报作品资格审查。</p>

        <p>初评阶段：根据全国评审委员会授权，组织专家进行初评，遴选入围终评的作品。</p>

        <p>
          终评阶段：协调主办单位、承办单位组织实施创新大赛终评活动，协调各省级机构组织本省入围终评的学生和科技辅导员参加终评活动。
        </p>

        <p>
          日常管理：根据需要提出修改章程的建议；组织修订竞赛规则；筹集竞赛活动经费；组织管理专项奖；开展与竞赛相关的培训和宣传推广工作；对省级竞赛的开展进行指导、监督和评估；负责受理创新大赛相关质疑投诉；提出下一届创新大赛终评活动举办地的建议。
        </p>

        <p>
          <strong> 第二十四条 </strong
          >省级竞赛组织机构负责本省创新大赛的组织管理工作，广泛发动和指导各级各类中小学校和广大师生规范开展青少年科技实践活动，完善本级竞赛的制度规则和实施细则，组织开展好竞赛作品申报、审查、评审和表彰奖励工作，并结合本省实际组织展示交流活动。省级创新大赛需建立创新大赛评审、监督、科学道德和伦理审查机制和工作委员会，并接受全国组织委员会的监督；在竞赛结束后及时向社会公示获奖名单，并向全国组织委员会进行备案；省级组织机构应按全国组织委员会要求，做好推荐参加全国竞赛代表和作品的审核把关，协助核实调查有关投诉，并及时据实报告调查结果。
        </p>

        <strong class="chapter">第六章&nbsp; 监督处理</strong>

        <p>
          <strong> 第二十五条</strong>
          全国组织委员会秘书处负责受理创新大赛相关质疑投诉，根据质疑投诉内容分别移交评审监督委员会或科学道德和伦理审查委员会开展核查，对涉及的组织程序、学术规范、科研伦理等相关问题进行调查。
        </p>

        <p>
          <strong> 第二十六条 </strong
          >被质疑作品或参赛人员违规情况的事实、性质、情节等经核实认定后，组委会将取消相关人员参赛或获奖资格；指导教师本人及其所指导作品视情节1-3年内不得参加全国竞赛；作者所在学校视情节1-3年内不得推荐参评全国十佳科技教育创新学校评选。
        </p>

        <p>
          <strong> 第二十七条</strong>
          建立评审委员会、评审监督委员会和科学道德和伦理审查委员会专家评估退出机制。如发现专家在评审、监督和审查过程中，存在违反评审纪律、干扰评审秩序、与竞赛相关人员有利益输送或利益交换等情况，经核实将不再聘请其参加大赛相关工作。情节严重的，通报专家所在单位。
        </p>

        <p>
          <strong> 第二十八条</strong>
          省级竞赛组织过程中或由其推荐参加全国竞赛的作品出现违规问题，省级竞赛组织部门应及时查找问题进行整改。如经查实为省级组织单位违反大赛章程或相关规则，造成不良社会影响的，将视情节扣减其下一届全国竞赛的参赛名额、取消省级优秀组织单位和优秀组织工作者评选资格、暂停或取消全国创新大赛推荐资格等。
        </p>

        <p>
          <strong> 第二十九条</strong>
          建立全国和基层竞赛失信人员名单和信息共享联动工作体系，推动创新大赛监督工作上下贯通，形成跨部门协同监管和联合惩戒机制。
        </p>

        <strong class="chapter">第七章&nbsp; 附&nbsp; 则</strong>

        <p>
          <strong> 第三十条 </strong
          >参赛者向主办单位提交作品即表示其自愿按照本章程规定参加创新大赛的活动，其所有参赛行为均受本章程的约束。
        </p>

        <p>
          <strong> 第三十一条 </strong
          >参赛者申报的作品不得侵犯第三方的专利权、著作权、商标权、名誉权或其他任何合法权益。创新大赛主办单位有权对参赛作品进行展览、出版、发行以及在其他公益科普活动中使用。
        </p>

        <p>
          <strong> 第三十二条 </strong
          >对于参赛者未在参赛前申请知识产权方面的保护而造成的损害，或参赛作品存在侵犯第三人专利权、著作权、商标权、肖像权、名誉权和隐私权等合法权益的，参赛者应当依法承担相关责任；因不可抗力造成创新大赛延期或取消举办的，主办单位不承担任何法律责任。
        </p>

        <p><strong> 第三十三条 </strong>本《章程》由中国科协负责解释，于发布之日起实施。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  .article {
    width: 1200px;
    margin: auto;
    text-align: center;
    font-size: 19px;
    color: #333;
    margin-bottom: 25px;
    line-height: 2;
    padding: 40px 0;
    &-title {
      margin-bottom: 15px;
      padding-top: 10px;
    }
  }
  .detail {
    font-size: 15px;
  }
  p {
    text-align: justify;
    text-indent: 2em;
    margin-bottom: 15px;
    strong {
      font-weight: bold;
    }
  }
  .chapter {
    display: inline-block;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
  }
}
@media (max-width: 768px) {
  .container {
    width: 100%;
    .article {
      width: 6.9rem;
      font-size: .33rem;
      margin-bottom: .2rem;
      padding: 0.4rem 0;
      &-title {
        margin-bottom: 0.15rem;
        padding-top: 0.05rem;
      }
    }
    .detail {
      font-size: var(--font-size-l-m);
    }
    p {
      margin-bottom: 0.15rem;
    }
    .chapter {
      margin-bottom: 0.15rem;
    }
  }
}
</style>>

<template>
  <div class="header">
    <div class="top">
      <div class="logo">
        <a :href="pathName"><img src="@/assets/images/header/logo-cxds.png" alt="" class="logo-cxds" /></a>
        <a class="show-lg" href="https://cyscc.org/" target="_blank"
          ><img src="@/assets/images/logo-kczm-1.png" alt="" class="logo-kczm"
        /></a>
        <div
          :class="'mobile-menu ' + (mobileMenuActive ? 'active' : '')"
          @click="mobileMenuActive = !mobileMenuActive"
        ></div>
      </div>
    </div>
    <div class="nav-bar">
      <HeaderNav :isOpen="mobileMenuActive" @isMenuClose="isMenuClose"/>
      <HeaderSearch/>
      <div v-if="this.isLogin" class="cysccHeader-user_info">
              <el-dropdown>
                <div @click="toMineCenter">
                  <span class="el-dropdown-link">
                    <img
                      class="avatar"
                      :src="userInfo.photo || require('../../assets/images/header/nav_user@2x.png')"
                    />

                    <span class="name" :title="userInfo && userInfo.name">{{
                      userInfo && userInfo.name | ellipsis(15)
                    }}</span>
                  </span>
                </div>
              </el-dropdown>
            </div>
      <div class="btn-group" v-else>
        <a class="user-btn login" target="_blank" href="https://newpassport.cyscc.org/login?back_url=https://www.cyscc.org/#/login/">登录</a>
        <a class="user-btn reg" target="_blank" href="https://newpassport.cyscc.org/signup?back_url=https://www.cyscc.org/">注册</a>
        
      </div>
    </div>
  </div>
</template>

<script>
import HeaderNav from './HeaderNav.vue'
import HeaderSearch from './HeaderSearch.vue'
export default {
  data () {
    return {
      mobileMenuActive: false,
      pathName:window.location.pathname,
      isAdmin: false,
    }
  },
  filters: {
    ellipsis: function(value,len) {
    // console.log(value.length,len)
      if (!value) return "";
      if (value.length > len) {
        return value.slice(0, len) + "...";
      }
      return value;
    }
  },
  computed: {
    // 判断是否已登录。
    isLogin () {
      return 1 && this.userInfo
    },
    userInfo () {
      let userData
      try {
        userData = JSON.parse(localStorage.getItem('userInfo'))

      }
      catch (e) {
        userData = {}
      }
      return userData
    },
  },
  components: {
    HeaderNav,
    HeaderSearch
  },
  methods : {
    isMenuClose(val) {
      this.mobileMenuActive = val
    },
    toMineCenter(){
      window.location.href = window.location.origin + '/#/mine/center'
    }

  },
  mounted(){
    this.isAdmin = (this.userInfo && this.userInfo['roleAuthorities'].indexOf('ROLE_ADMIN') !== -1)
    
  }
}
</script>

<style scoped lang="less">
.header {
  width: 100%;
  min-width: var(--main-width);
  background-color: #fff;
  z-index:2;
  position: relative;
  .btn-group{
    margin-left: -80px;
  }
  .user-btn{
    display:inline-block;
    margin: 13px 0;
    border-radius: 17px;
    height: 34px;
    line-height: 34px;
    width: 67px;
    text-align: center;
    background: #fff;
    font-size: 16px;
    color: #3580ff;
    &.reg {
      color: #497ef6;
      background-color: #d8e6ff;
      padding-left: 20px;
      width:85px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &.login {
      position:relative;
      color: #fff;
      background-color: #497ef6;
      margin-right: -30px;
      padding: 0 4px;
      z-index:1;
    }
  }
  .top {
    width: 100%;
    border-bottom: 2px solid rgba(138, 157, 170, 0.1);
    .logo {
      width: var(--main-width);
      height: 88px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      .logo-cxds {
        width: 303px;
      }
      .logo-kczm {
        width: 174px;
      }
    }
  }
}
.nav-bar {
  display: flex;
  justify-content: space-between;
  width: var(--main-width);
  margin: 0 auto;
}
.mobile-menu {
  display: none;
}
.cysccHeader-user_info {
      font-size: 16px;
      color: #303133;
      line-height: 60px;
      .avatar {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        position: relative;
        top: 11px;
        &:hover {
          cursor: pointer;
        }
      }

      .name {
        padding: 0px 0 0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        float: right;
        max-width: 90px;
        color: #606266;
        &:hover {
          cursor: pointer;
        }
      }
    }

@media (max-width: 768px) {
  .cysccHeader-user_info {
        display: none;
      }
  .header {
    width: 100%;
    min-width: inherit;
    background: #fff;

    padding: 0.15rem 0.4rem 0.25rem;
    position: relative;
    .show-lg {
      display: none;
    }
    .top {
      min-width: auto;
      border-bottom: none;
      margin-bottom: 0.15rem;
      .logo {
        width: 100%;
        height: 1rem;

        .logo-cxds {
          width: 3.99rem;
          height: .72rem;
          vertical-align: middle;
        }

        .mobile-menu {
          display: block;
          background: url('../../assets/images/header/menu.png') no-repeat center;
          background-size: 0.4rem 0.31rem;
          width: 0.4rem;
          height: 0.4rem;
        }
        .mobile-menu.active {
          background-image: url('../../assets/images/header/close.png');
          background-size: 0.32rem 0.32rem;
        }
      }
    }
    .nav-bar {
      width: inherit;
      display: block;
      .user-btn{
        display:none;
      }
    }
  }
}
</style>

<!-- 一个swiper页面包含多个项目的swiper。 -->
<template>
  <div class="multi-swiper-container" :id="id">
    <swiper ref="swiper" :options="swiperOption">
      <swiper-slide v-for="(page, index) in dataProcessed" :key="index">
        <div :class="itemClass" v-for="(item, index) in page.items" :key="index">
          <a :href="item.linkUrl" target="_blank">
            <img :src="item.imageUrl" alt="" style="margin-top: 5px; margin-bottom: 7px; box-sizing: border-box" />
            <div class="desc">{{ item.title }}</div>
          </a>
        </div>
      </swiper-slide>
    </swiper>
    <div class="nav">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>

<script >

export default {
  name: 'MultiItemSwiper',
  props: {
    // 每一个元素，需要包含 src属性、link属性。
    dataList: {
      type: Array,
      default: () => []
    },
    itemClass: {
      type: String,
      default: ''
    }
  },

  data () {
    const id = 'm-t-s-' + Math.round(Math.random() * 1000000000)
    return {
      baseUrl: this.$VUE_APP_PUBLIC,
      id: id,
      itemsPerRow: 4,
      rowsPerPage: 2,
      swiperOption: {
        autoplay: false,
        observer: true,
        loop: false,
        navigation: {
          nextEl: '#' + id + ' .swiper-button-next',
          prevEl: '#' + id + ' .swiper-button-prev',
        },

      }

    }
  },
  computed: {
    /*
    生成的结构体如下：
    [
      {
        items:Array
      },
      ...
    ]
     */
    dataProcessed () {
      const itemsPerPage = this.itemsPerRow * this.rowsPerPage
      let processedList = []
      let activePage
      this.dataList.forEach((item, index) => {
        const itemBelongsPage = Math.ceil((index + 1) / itemsPerPage)
        activePage = processedList[itemBelongsPage - 1]
        if (!activePage) {
          activePage = { items: [] }
          processedList.push(activePage)
        }
        activePage.items.push(item)
      })
      return processedList
    },

    prevBtn () {
      return this.$refs.swiper.querySelector('.swiper-button-prev')
    },
    nextBtn () {
      return this.$refs.swiper.querySelector('.swiper-button-next')
    }
  },
  methods: {

  },
  mounted () {
  }
}
</script>

<style scoped lang="less">
.multi-swiper-container {
  position: relative;
  .swiper-container {
    height: 100%;
  }
  /deep/.swiper-wrapper {
    align-items: center;

    .swiper-slide {
      height: auto;
    }
  }
}

@media (max-width: 768px) {
}
</style>
<template>
  <div>
    <ul :class="'list ' + (showDate ? 'has-date' : '')" v-if="dataList.length > 0">
      <li v-for="item in dataList" :key="item.id">
        <router-link :to="item.link" class="more">{{ item.title }}</router-link>
        <span class="date" v-if="showDate && item.createTime">{{ item.createTime }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TextList',
  props: {
    dataList: {
      type: Array,
      require: true
    },
    showDate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      
    }
  }
}
</script>

<style scoped lang="less">
  .list {
    li {
      padding-left: 20px;
      padding-bottom: 20px;
      padding-top: 20px;
      border-bottom: 1px solid #eee;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        color: #333333;
      }
      .date {
        color: #999;
        position: absolute;
        right: 0;
      }
      &::before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        background: #dcdfe6;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        margin-top: -7px;
        left: 0px;
      }
    }
  }
  .list.has-date {
    li {
      padding-right: 150px;
    }
  }


@media (max-width: 768px) {
    .list {
      li {
        margin-top: 0rem;
        padding-left: 0.49rem;
        padding-right: 0;
        padding-bottom:.15rem;
        a {
          letter-spacing: 0;
        }

        &::before {
          content: '';
          width: 0.18rem;
          height: 0.18rem;
          margin-top: -0.09rem;
        }
      }
    }
    .list.has-date {
      .date {
        position: static;
        display: block;
        text-align: right;
        color: #d2c8c8;
        margin-top: .1rem;
      }
      li {
        padding-right: 0;
        padding-left: 0.38rem;

        &::before {
          margin-top: 0.02rem;
        }
      }
    }
}
</style>
<template>
  <div class="page">
    <div class="banner-top"></div>
    <div class="title title-dsgk"></div>
    <div class="main-width-container">
      <div class="intro-container">
        <p>
          全国青少年科技创新大赛是由中国科协、自然科学基金委、共青团中央、全国妇联共同主办的一项全国性的青少年科技竞赛活动。大赛具有广泛的活动基础，从基层学校到全国大赛，每年约有1000万名青少年参加不同层次的活动，经过选拔，500多名的青少年科技爱好者、200名科技辅导员相聚一起进行竞赛、展示和交流活动。全国青少年科技创新大赛不仅是国内青少年科技爱好者的一项重要赛事，而且已与国际上许多青少年科技竞赛活动建立了联系，每年都从大赛中选拔出优秀的科学研究项目参加国际科学与工程大奖赛(ISEF)、欧盟青少年科学家竞赛等国际青少年科技竞赛活动。
        </p>
        <p>
          如果想更加全面的了解全国青少年科技创新大赛，请阅读
          《全国青少年科技创新大赛章程》
          和各项比赛规则。
        </p>
      </div>
      <!-- 顶部左侧轮播、右侧三个文件 -->
      <div class="focus-area main-width-container">
        <div class="focus-swiper">
          <swiper :options="swiperOption" class="banner-swiper" v-if="situationList.length > 0">
            <!-- slides -->
            <swiper-slide v-for="(item, index) in situationList" :key="index" style="width: 681px; height: 393px">
              <a
                :href="item.url.length != 0 ? item.url : 'javascript:;'"
                :target="item.url.length != 0 ? '_blank' : ''"
                :style="item.url.length != 0 ? 'cursor:pointer' : 'cursor:default'"
                ><img :src="item.image" style="width: 100%; height: 100%" />
              </a>
            </swiper-slide>
          </swiper>
          <div class="progress"></div>
        </div>
        <div class="file-container">
          <div class="file"><a href="#" @click="open">全国青少年科技创新大赛章程</a></div>
          <div class="file"><a href="#" @click="openJsgz">青少年科技创新成果竞赛规则</a></div>
          <div class="file"><a href="#" @click="openKjfd">科技辅导员科技教育创新成果 竞赛规则</a></div>
        </div>
      </div>
    </div>
    <div class="block-lsyg">
      <div class="title title-lsyg" ref="lsyg"></div>
      <div class="main-width-container">
        <div class="hint-scroll"></div>
        <Lsyg :timelineData="historyList" />
      </div>
    </div>
    <div class="block-zzjg">
      <div class="title title-zzjg" ref="zzjg"></div>
      <div class="main-width-container">
        <Zzjg />
      </div>
    </div>
  </div>
</template>

<script>
import Zzjg from './Zzjg.vue'
import Lsyg from './Lsyg.vue'
import { getBannerList } from '../../api/home'
import { getHistoryList } from '../../api/competition'
export default {
  props: {

  },
  data () {
    return {
      situationList: [],
      historyList: [],
      baseUrl: this.$VUE_APP_PUBLIC,
      swiperOption: {
        autoplay: true,
        loop: true,
        pagination: {
          el: `.focus-swiper .progress`
        }
      }
    }
  },
  components: {
    Zzjg, Lsyg
  },
  created () {
    this.getSituationList()
    this.getHistoryList()
  },
  methods: {
    open () {
      let url = this.$router.resolve('/about/article/dszc')
      window.open(url.href)
    },
    openJsgz () {
      let url = this.$router.resolve('/about/article/jsgz')
      window.open(url.href)
    },
    openKjfd () {
      let url = this.$router.resolve('/about/article/kjfd')
      window.open(url.href)
    },
    scrollMeTo (refName) {
      const element = this.$refs[refName]
      const top = element.offsetTop
      window.scrollTo(0, top)
    },
    // 获取大赛概况列表
    async getSituationList () {
      const res = await getBannerList(2)
      this.situationList = res.data
    },
    // 获取历史沿革列表
    async getHistoryList () {
      const res = await getHistoryList()
      this.historyList = res.data
    },
  },
  mounted () {
    const part = this.$route.params.part
    if (part) {
      this.scrollMeTo(part)
    }
  }
}
</script>


<style scoped lang="less">
.page {
  .banner-top {
    height: 400px;
    background-image: url('../../assets/images/about/banner-top.jpg');
    background-repeat: no-repeat;
    background-size: 1920px 400px;
    background-position: center;
  }
  .title-dsgk {
    background-image: url('../../assets/images/about/title-dsgk.png');
  }
  .title-lsyg {
    background-image: url('../../assets/images/about/title-lsyg.png');
  }
  .title-zzjg {
    background-image: url('../../assets/images/about/title-zzjg.png');
  }
  .intro-container {
    background-image: url('../../assets/images/about/bg-intro.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 45px;
    color: #666;
    line-height: 2.1;
    text-indent: 2em;
    span {
      font-size: 1.5em;
      color: var(--main-color-2);
      cursor: pointer;
    }
  }

  .block-lsyg {
    background-image: url('../../assets/images/about/bg-lsyg.jpg');
    background-size: 100% 100%;
    background-position: center;
    min-height: 1053px;

    .hint-scroll {
      background-image: url('../../assets/images/about/hint-drag.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 298px;
      height: 48px;
      margin: 0 auto 50px;
    }
  }
  .block-zzjg {
    &::before {
      content: '';
      display: table;
    }
    background-image: url('../../assets/images/about/bg-zzjg.jpg');
    background-size: 100% 100%;
    background-position: center;
    min-height: 985px;
    margin-bottom: -300px;
  }
  .focus-area {
    margin-top: 60px;
    display: flex;
    gap: 35px;
    .focus-swiper {
      width: 681px;
      height: 383px;
      border-radius: 8px;
      overflow: hidden;
    }
    .progress {
      width: 100%;
      text-align: center;
      margin-top: -45px;
      position: relative;
      z-index: 2;
    }
    .swiper-pagination-bullets {
      padding-top: 10px;
      text-align: center;
      /deep/.swiper-pagination-bullet {
        margin: 0 4px;
        height: 3px;
        width: 30px;
        background-color: #fff;
        border-radius: 0%;
      }
      /deep/.swiper-pagination-bullet-active {
        background-color: #fff;
      }
    }
    .file-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .file {
        background-image: url('../../assets/images/about/bg-file-1.png');
        background-size: 100% 100%;
        width: 480px;
        height: 107px;
        padding-left: 130px;
        padding-right: 40px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        line-height: 1.6;
        .file-2 {
          background-image: url('../../assets/images/about/bg-file-2.png');
        }
        a {
          color: #fff;
          text-decoration: underline;
          font-size: var(--font-size-l);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .page {
    .banner-top {
      height: 2.68rem;
      background-size: 12.864rem 2.68rem;
    }

    .title-dsgk {
      background-image: url('../../assets/images/about/title-dsgk-m.png');
    }
    .title-lsyg {
      background-image: url('../../assets/images/about/title-lsyg-m.png');
    }
    .title-zzjg {
      background-image: url('../../assets/images/about/title-zzjg-m.png');
    }

    .intro-container {
      background-image: url('../../assets/images/about/bg-intro-m.png');
      padding: 0.3rem;
    }

    .focus-area {
      margin: 0;
      margin-top: 0.8rem;
      display: block;

      .focus-swiper {
        width: 100%;
        height: auto;
        aspect-ratio: 16 / 9;
        border-radius: 0.1rem;
        margin-bottom: 0.3rem;
      }
      .progress {
        margin-top: -0.6rem;
      }
      .swiper-pagination-bullets {
        padding-top: 0;
        /deep/.swiper-pagination-bullet {
          margin: 0 0.1rem;
          height: 0.05rem;
          width: 0.5rem;
        }
      }
      .file-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .file {
          background-image: url('../../assets/images/about/bg-file-1-m.png');
          width: 6.84rem;
          height: 1.07rem;
          padding-left: 1.3rem;
          padding-right: 0.3rem;
          margin-bottom: 0.2rem;
          .file-2 {
            background-image: url('../../assets/images/about/bg-file-2-m.png');
          }
          a {
            text-decoration: none;
            font-size: var(--font-size-l-m);
          }
        }
      }
    }

    .block-lsyg {
      background-image: url('../../assets/images/about/bg-lsyg.jpg');
      min-height: auto;

      .hint-scroll {
        background-image: url('../../assets/images/about/hint-drag.png');
        width: 4.47rem;
        height: 0.72rem;
        margin: 0 auto 0.5rem;
      }
    }
    .block-zzjg {
      background-image: url('../../assets/images/about/bg-zzjg.jpg');
      background-size: 100% 100%;
      background-position: center;
      min-height: auto;
      margin-bottom: -3rem;
      padding-bottom: 3rem;
    }
  }
  .banner-swiper {
    img {
      width: 6.86rem !important;
      height: 3.86rem !important;
    }
  }
}
</style>
/* 首页顶部轮播大banner轮播图组件所需的数据 ,图片大小为1920*500*/
export const bannerSwiperData = {
  "status":200,
  "data":[
    {id:1,
      "src":"/testImages/banner.jpg",
      "link":"http://www.baidu.com"
    },
    {id:2,
      "src":"/testImages/banner-1.jpeg",
      "title":"有title的轮播图",
      "link":"http://www.google.com"
    },
   
  ]
}

/* 页面内轮播图组件所需的数据 */
export const photoSwiperData = {
  "status":200,
  "data":[
    {id:1,
      "src":"/testImages/placeholder-16-9-1.jpg",
      "title":"第36届全国青少年科技创新大赛线上展示交流活动填写回执信息操作说明",
      "link":"www.baidu.com"
    },
    {id:2,
      "src":"/testImages/placeholder-16-9-2.jpg",
      "title":"关于组织开展全国青少年科技创新大赛四十周年主题活动的通知",
      "link":"www.163.com"
    },
    {id:3,
      "src":"/testImages/placeholder-16-9-1.jpg",
      "title":"第36届全国青少年科技创新大赛线上展示交流活动填写回执信息操作说明",
      "link":"www.sohu.com"
    },
    {id:4,
      "src":"/testImages/placeholder-16-9-2.jpg",
      "title":"关于组织开展全国青少年科技创新大赛四十周年主题活动的通知",
      "link":"www.google.com"
    },
  ]
}
/* 页面内新闻列表等列表形式所需的数据 */
export const newsListData = {
  "status":200,
  "count":60,
  "data":[
    {
      id: 1,
      title: '关于组织开展全国青少年科技创新大赛四十周年主题活动的通知',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
    {
      id: 2,
      title: '中国科协主席与海峡两岸暨港澳青少年见面会举行',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
    {
      id: 3,
      title: '关于组织开展全国青少年科技创新大赛四十周年主题活动的通知',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
    {
      id: 4,
      title: '关于组织开展全国青少年科技创新大赛四十周年主题活动的通知',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
    {
      id: 5,
      title: '中国科协主席与海峡两岸暨港澳青少年见面会举行',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
  ]
}
/* 首页【文件通知、名单公示】所需的数据 */
export const fileListData = {
  "status":200,
  "data":[
    {
      id: 1,
      title: '这是个通知公示',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
    {
      id: 2,
      title: '关于全国青少年科技创新大赛获奖项目相关情况的声明',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
    {
      id: 3,
      title: '第34届全国青少年科技创新大赛圆满落幕',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
    {
      id: 4,
      title: '关于全国青少年科技创新大赛获奖项目相关情况的声明',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
    {
      id: 5,
      title: '第34届全国青少年科技创新大赛圆满落幕',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
    {
      id: 6,
      title: '第六条通知',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
  ]
}

export const nameListData = {
  "status":200,
  "data":[
    {
      id: 1,
      title: '这是个名单公示',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
    {
      id: 2,
      title: '第34届全国青少年科技创新大赛圆满落幕',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
    {
      id: 3,
      title: '关于全国青少年科技创新大赛获奖项目相关情况的声明',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
    {
      id: 4,
      title: '关于全国青少年科技创新大赛获奖项目相关情况的声明',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
    {
      id: 5,
      title: '第34届全国青少年科技创新大赛圆满落幕',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
    {
      id: 6,
      title: '第六条通知',
      link:'www.baidu.com',
      createTime:'2022-05-15'
    },
  ]
}


/* 首页活动掠影卡片数据 */
export const cardListData = {
  "status":200,
  "count":60,
  "data":[
    {
      "id": 1,
      "src":"/testImages/placeholder-16-9-1.jpg",
      "title": '闭幕式暨颁奖典礼',
      "link":'http://www.baidu.com',
      'view':3212,
      "createTime":'2022-07-15'
    },
    {
      "id": 2,
      "src":"/testImages/placeholder-16-9-2.jpg",
      "title": '中国科技主席与海峡两岸中国科技主席与海峡两岸中国科技主席与海峡两岸',
      "link":'www.baidu.com',
      'view':1234,
      "createTime":'2022-07-10'
    },
    {
      "id": 3,
      "src":"/testImages/placeholder-16-9-3.jpg",
      "title": '中国科技主席与海峡两岸中国科技主席与海峡两岸',
      "link":'www.baidu.com',
      'view':32112,
      "createTime":'2022-06-29'
    },
    {
      "id": 4,
      "src":"/testImages/placeholder-16-9-4.jpg",
      "title": '科技教师公开坊',
      "link":'www.baidu.com',
      'view':1693,
      "createTime":'2022-06-15'
    },
    {
      "id": 5,
      "src":"/testImages/placeholder-16-9-5.jpg",
      "title": '国际青少年科技交流活动',
      "link":'www.baidu.com',
      'view':21,
      "createTime":'2022-05-15'
    },
    {
      "id": 6,
      "src":"/testImages/placeholder-16-9-6.jpg",
      "title": '科技教师公开坊',
      "link":'www.baidu.com',
      'view':21,
      "createTime":'2022-05-15'
    },
    {
      "id": 7,
      "src":"/testImages/placeholder-16-9-7.jpg",
      "title": '我的创新故事：什么是科学素养',
      "link":'www.baidu.com',
      'view':21,
      "createTime":'2022-05-15'
    },
    {
      "id": 8,
      "src":"/testImages/placeholder-16-9-8.jpg",
      "title": '国际青少年科技交流活动',
      "link":'www.baidu.com',
      'view':21,
      "createTime":'2022-05-15'
    }
  ]
}








/* 首页用的人员卡片数据，3条（优秀科技辅导员） */
export const personCardListDataHome = {
  "status":200,
  "count":60,
  "data":[
    {
      "id": 1,
      "src":"/testImages/placeholder-photo-1.png",
      "name": '白武明',
      'title':'中国科学院老科学家',
      "link":'',
      'desc':'个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介'
    },
    {
      "id": 2,
      "src":"/testImages/placeholder-photo-2.png",
      "name": '白武明2',
      'title':'中国科学院老科学家',
      "link":'',
      'desc':'个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介'
    },{
      "id": 3,
      "src":"/testImages/placeholder-photo-3.png",
      "name": '白武明3',
      'title':'中国科学院老科学家',
      "link":'',
      'desc':'个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介'
    }
  ]
}
/* 首页用的人员卡片数据，3条（优秀获奖学生） */
export const studentCardListDataHome = {
  "status":200,
  "count":60,
  "data":[
    {
      "id": 1,
      "src":"",
      "name": '张晓妮',
      'title':'北京惠文中学',
      "link":'',
      'desc':'个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介'
    },
    {
      "id": 2,
      "src":"/testImages/placeholder-student.webp",
      "name": '小白',
      'title':'中国科学院老科学家',
      "link":'',
      'desc':'个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介'
    },{
      "id": 3,
      "src":"",
      "name": '小吕',
      'title':'中国科学院老科学家',
      "link":'',
      'desc':'个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介个人简介'
    }
  ]
}
/* 首页用的学校卡片数据，3条（科技创新优秀学校） */
export const schoolCardListDataHome = {
  "status":200,
  "count":60,
  "data":[
    {
      "id": 1,
      "src":"/testImages/placeholder-school.jpg",
      "name": '清华大学',
      'desc':'清华大学前身清华学堂始建于1911年，1928年更名为国立清华大学，面向未来，清华大学将秉持“自强不息、厚德载物”的校训和“行胜于言”的校风，努力在创建世界一流大学方面走在前列.'
    },
    {
      "id": 2,
      "src":"",
      "name": '北京大学',
      'desc':'北京大学创办于1898年，初名京师大学堂，是中国第一所国立综合性大学，于1912年改为现名。近年来，在“211工程”和“985工程”的支持下，北京大学进入了一个新的历史发展阶段'
    },{
      "id": 3,
      "src":"",
      "name": '人民大学',
      'desc':'中国人民大学（Renmin University of China）是中国共产党创办的第一所新型正规大学，是一所以人文社会科学为主的综合性研究型全国重点大学'
    }
  ]
}

<template>
  <div class="card-simple" @click="checkDetail(cardData.id)">
    <div class="card-top clearfix">
      <div class="img-container"  style="cursor: pointer">
        <img :src="cardData.imageUrl" alt="" />
      </div>
      <div class="school-name limit-line line1">{{ cardData.name }}</div>
    </div>
    <div class="desc-container">
      <div class="desc" v-html="cardData.desc"></div>
    </div>
    <el-dialog title="详情" :visible.sync="previewStatus" width="80%" v-if="form" close-on-click-modal>
      <div class="article">
        <div class="main">
          <div class="title-cotnainer">
            <div class="result-title">{{ form.name }}</div>
            <div class="school-title">{{ form.title }}</div>
          </div>
          <div class="article-text" v-html="form.desc"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getResultDetail } from '../../api/competition'

export default {
  name: 'CardItemSchool',
  props: {
    cardData: {
      type: Object,
      require: true
    },

  },
  data () {
    return {
      form: null,
      previewStatus: false
    }
  },
  methods: {
    async checkDetail (id) {
      const res = await getResultDetail(id)
      if (res.code == 200) {
        this.form = res.data
      }
      this.$router.push({
        path: 'achievement/article/'+id,
       
      })
    },
  }
}
</script>

<style scoped lang="less">
.card-simple {
  position: relative;
  width: 373px;
  height: 357px;
  border: 1px solid #e8eff9;
  background: linear-gradient(0deg, #f7faff 0%, #ffffff 100%);
  box-shadow: 0px 9px 13px 0px rgba(197, 207, 217, 0.25);
  border-radius: 8px;
  cursor: pointer;

  overflow: hidden;

  display: inline-block;
  padding: 15px 20px;
  margin-right: 40px;
  margin-bottom: 25px;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .img-container {
    width: 330px;
    height: 186px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .school-name {
    margin-top: 8px;
    font-size: var(--font-size-xl);
  }

  .card-top {
    border-bottom: 2px solid #1b7fd3;
    padding-bottom: 5px;
  }
  .desc-container {
    margin-top: 1px;
    border-top: 1px solid #1b7fd3;
    padding-top: 5px;
  }

  .desc {
    color: #666;
    letter-spacing: 1px;
    line-height: 1.8;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.article {
  padding: 20px;
  padding-bottom: 50px;
  min-height: 500px;
  border: 1px solid #d9d9d9;
  background: linear-gradient(0deg, #f7faff 0%, #ffffff 100%);
}
.result-title {
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}
.school-title {
  border-bottom: 1px solid #999;
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.article-text {
  line-height: 1.5;
  font-size: var(--font-size-m);
}

@media (max-width: 768px) {
  .article-text {
    font-size: var(--font-size-m-m);
  }
  .article {
    padding: 0.1rem;
    padding-bottom: 0.5rem;
    min-height: 7rem;
  }
  .result-title {
    font-size: var(--font-size-l-m);
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
  .school-title {
    font-size: var(--font-size-l-m);
    padding-bottom: 0.2rem;
    margin-bottom: 0.2rem;
  }
  .card-simple {
    width: 100%;
    height: 6.57rem;
    margin: 0.12rem 0rem;

    .img-container {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
      margin-right: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .person-name {
      margin-left: 2.3rem;
      font-size: var(--font-size-xl);
    }

    .card-title {
      margin: 0.15rem 0.21rem 0.19rem 2.3rem;
      padding: 0;
      line-height: 0.4rem;
      font-size: var(--font-size-l-m);
      color: #111;
    }
  }
}
</style>
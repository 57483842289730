<template>
  <div class="province-container">
    <div class="province-selector">
      <div
        :class="'province-name ' + (activeProvince == item ? 'active' : '')"
        v-for="item in institutionList"
        :key="item.id"
        @click="handleActiveItem(item)"
      >
        {{ item.cityName }}
      </div>
    </div>
    <div class="province-info" v-if="isShow && activeProvince">
      <div class="province">
        <div class="org-name" v-if="isShow">{{ activeProvince.title }}</div>
        <div class="org-contact">
          <div class="org-tel">{{ activeProvince.telephone }}</div>
          <div class="org-email">{{ activeProvince.email }}</div>
          <div class="org-website">{{ activeProvince.linkUrl }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInstitution } from '../../api/competition'
export default {
  data () {
    return {
      activeProvince: null,
      institutionList: [],
      isShow: false
    }
  },
  components: {

  },
  methods: {
    // 获取组织机构列表
    async getInstitutionList () {
      const res = await getInstitution()
      this.institutionList = res.data
      this.activeProvince = this.institutionList ? this.institutionList[0] : {}
      this.isShow = true
    },
    // 当前选择省份
    handleActiveItem (item) {
      this.isShow = false
      this.activeProvince = item
      this.isShow = true
    }
  },

  mounted () {
    this.getInstitutionList()
  }
}
</script>


<style scoped lang="less">
.province-container {
  background-image: url('../../assets/images/about/bg-province.png');
  background-repeat: no-repeat;
  height: 523px;
  background-size: 100% 100%;
  color: #fff;
  display: flex;
  padding: 46px;

  .province-selector {
    width: 710px;
    font-size: 0;
    padding-left: 40px;

    .province-name {
      display: inline-block;
      width: 113px;
      font-size: var(--font-size-l);
      line-height: 54px;
      text-align: center;
      margin-top: 8px;
      cursor: pointer;
      border-top-left-radius: 15px;
      border-bottom-right-radius: 15px;
      &.active {
        background: #fb0000;
      }
    }
  }
  .province-info {
    width: 438px;
    border-left: 2px solid rgba(238, 238, 238, 0.2);
    padding: 33px 0 33px 33px;
    .org-name {
      font-size: 30px;
      // height: 120px;
      margin-bottom: 80px;
      line-height: 1.5;
      letter-spacing: 0.02rem;
    }
    .org-contact {
      background-image: linear-gradient(0deg, #3891ff 0%, rgba(56, 145, 255, 0) 100%);
      min-height: 204px;
      padding: 40px 20px 20px 30px;
    }
    .org-tel,
    .org-email,
    .org-website {
      font-size: var(--font-size-l);
      min-height: 50px;
      display: flex;
      align-items: flex-start;
      padding-top: 10px;
      background-repeat: no-repeat;
      background-position: left 13px;
      padding-left: 40px;
      word-break: break-all;
    }
    .org-tel {
      background-image: url('../../assets/images/about/icon-tel.png');
    }
    .org-email {
      background-image: url('../../assets/images/about/icon-email.png');
    }
    .org-website {
      background-image: url('../../assets/images/about/icon-website.png');
    }
  }
}

@media (max-width: 768px) {
  .province-container {
    background-image: url('../../assets/images/about/bg-province-m.png');
    background-repeat: no-repeat;
    height: 11.75rem;
    background-size: 100% 100%;
    color: #fff;
    display: block;
    padding: 0.3rem;
    .province-selector {
      width: 100%;

      padding: 0;

      .province-name {
        width: 1.56rem;
        font-size: var(--font-size-l-m);
        line-height: 2;
        margin-top: 0.15rem;
        border-top-left-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
      }
    }
    .province-info {
      width: 100%;
      border-left: 0;
      border-top: 1px solid rgba(238, 238, 238, 0.2);
      margin-top: 0.3rem;
      padding: 0.3rem 0;
      .org-name {
        font-size: var(--font-size-xl-m);
        font-weight: bold;
        // height: 1.2rem;
        margin-bottom: .7rem;
        line-height: 1.5;
      }
      .org-contact {
        min-height: 2rem;
        padding: 0.3rem;
      }
      .org-tel,
      .org-email,
      .org-website {
        font-size: var(--font-size-l-m);
        min-height: 0.6rem;

        padding-top: 0.2rem;

        background-position: left 0.25rem;
        padding-left: 0.5rem;
        word-break: break-all;
      }
      .org-tel {
        background-image: url('../../assets/images/about/icon-tel.png');
        background-size: 0.313rem 0.336rem;
      }
      .org-email {
        background-image: url('../../assets/images/about/icon-email.png');
        background-size: 0.324rem 0.255rem;
      }
      .org-website {
        background-image: url('../../assets/images/about/icon-website.png');
        background-size: 0.313rem 0.313rem;
      }
    }
  }
}
</style>
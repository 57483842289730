<template>
  <div class="list-container">
    <component
      :is="showType"
      :dataList="dataListTransLink"
      :showDate="showDate"
      :showSubType="showSubType"
      :showView="showView"
      :contentType="contentType"
    ></component>
    <el-pagination
      v-if="hasPagination && totalCount > 0"
      class="pagination"
      :small="pageWidth < 768 ? true : false"
      background
      prev-text="上一页"
      next-text="下一页"
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :current-page="page"
      :pager-count="5"
      :page-size="pageSize"
      :total="totalCount"
    >
    </el-pagination>
    <div class="no-result" v-if="totalCount == 0">暂无数据</div>
  </div>
</template>

<script>

import CardList from './CardList.vue'
import TextList from './TextList.vue'
export default {
  name: 'DataList',
  components: {
    CardList, TextList
  },
  props: {
    // 显示类型。text:文本列表 card:卡片列表
    showType: {
      default: 'TextList'
    },
    showSubType: {
      type: String,
      default: null
    },
    linkPattern: {
      type: String,
      default: '/news/article/{id}'
    },
    //数据数组，可能是text、card等形式展现。
    dataList: {
      type: Array,
      require: true
    },
    // 是否有分页。
    hasPagination: {
      type: Boolean,
      default: true
    },
    // 数据总条数
    totalCount: {
      type: Number,
      default: 0
    },
    // 每页显示条数
    pageSize: {
      type: Number,
      default: 10
    },
    // 是否显示日期信息，当前在text列表中，有部分需要展示日期
    showDate: {
      type: Boolean,
      default: true
    },
    // 是否展示浏览数，在部分卡片上使用，默认true（不是每个卡片都有此信息展示）
    showView: {
      type: Boolean,
      default: true
    },
    contentType: {
      type: String,
      default: null
    },
    page: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      // 部署的二级目录，会影响到图片等素材
      baseUrl: this.$VUE_APP_PUBLIC,
      // 默认当前页为第一页。
      currentPage: 1,
      pageWidth: window.innerWidth
    }
  },
  computed: {

    // 根据this.linkPattern转换link；根据baseUrl转换src（如果存在）
    dataListTransLink: function () {
      const regex = /\{(\w+)\}/
      const result = regex.exec(this.linkPattern)
      let replaceAttr = ''
      if (result.length == 2) {
        replaceAttr = result[1]
      }
      let transformedList = this.dataList.map(obj => obj = {
        ...obj,
        link: this.linkPattern.replace(result[0], obj[replaceAttr]),
        src: obj.src ? this.$VUE_APP_PUBLIC + obj.src : null
      })

      return transformedList
    }
  },
  methods: {
    handleCurrentChange (page) {
      this.currentPage = page
      this.$emit('handlePageChange', this.currentPage)
    }
  },
}
</script>

<style scoped lang="less">
.list-container {
  .list {
    li {
      padding-left: 20px;
      padding-bottom: 20px;
      padding-top: 20px;
      border-bottom: 1px solid #eee;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        color: #333333;
      }
      .date {
        color: #999;
        position: absolute;
        right: 0;
      }
      &::before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        background: #dcdfe6;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        margin-top: -7px;
        left: 0px;
      }
    }
  }
  .list.has-date {
    li {
      padding-right: 150px;
    }
  }
  .pagination {
    margin: auto;
    margin-top: 70px;
  }
  .el-pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  /deep/.el-pagination.is-background .btn-next,
  /deep/.el-pagination.is-background .btn-prev {
    padding: 0 10px;
    background-color: transparent;
  }

  /deep/.el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: transparent; /*修改未选中背景和字体*/
    color: #7a7a7a;
  }
  /deep/.el-pagination.is-background .el-pager li:hover {
    color: #7a7a7a;
    cursor: pointer;
  }
  /deep/.el-pagination.is-background .el-pager li.active {
    background-color: var(--main-color-1);
    color: #fff;
  }
  .no-result {
    background: url('../../assets/images/list/no-result-m.png') no-repeat center;
    width: 360.8px;
    height: 216.8px;
    text-align: center;
    padding-top: 180px;
    margin: 50px auto 0;
    color: #7da7dd;
  }
}

@media (max-width: 768px) {
  .list-container {
    .list {
      li {
        margin-top: 0.32rem;
        padding-left: 0.49rem;
        padding-right: 0;
        a {
          letter-spacing: 0;
        }

        &::before {
          content: '';
          width: 0.18rem;
          height: 0.18rem;
          margin-top: -0.09rem;
        }
      }
    }
    .list.has-date {
      .date {
        position: static;
        display: block;
        text-align: right;
        color: #d2c8c8;
      }
      li {
        padding-right: 0;
        padding-left: 0.3rem;

        &::before {
          margin-top: -0.32rem;
        }
      }
    }
    .el-pagination {
      margin-top: 0.4rem;
    }

    .no-result {
      width: 4.51rem;
      height: 3.71rem;
      background-size: 4.51rem 2.71rem;
      padding-top: 2.8rem;
      margin: 1.5rem auto 0;
    }
  }
}
</style>
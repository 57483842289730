import request from '@/utils/request'

// 首页轮播图
export const getBannerList = (type) => {
  return request({
    method: 'GET',
    url: '/banner/findBannerList?type=' + type
  })
}

// 首页文件、活动报道、掠影、名单
export const getHomeList = (type,status) => {
  return request({
    method: 'GET',
    url: '/inform/getHomeInform?type=' + type + '&status=' + status
  })
}

// 首页大赛成果
export const getResultList = (type) => {
  return request({
    method: 'GET',
    url: '/result/detail/getHomeInfo?type=' + type
  })
}


<!-- 设奖单位部分，在参与活动、设奖单位页面，都用到了 -->
<template>
  <MultiItemSwiper class="logo-swiper" :dataList="sponsorData" itemClass="logo-item"/>
</template>

<script>
import MultiItemSwiper from '@/components/MultiItemSwiper.vue'

export default {
  props: {
    sponsorData: {
      type: Array,
      require: true
    },

  },
  data () {
    return {

      }
  },
  components: {
    MultiItemSwiper
  },
  computed:{
  },
  methods :{

  },
  mounted(){
  }
}
</script>


<style scoped lang="less">
  /deep/.swiper-slide{
    font-size:0;
    text-align:center;
  }
  
  /deep/.logo-item{
    background-color: #fff;
    width: 258px;
    height: 121px;
    display:inline-block;
    font-size:initial;
    margin:8px;
    text-align:center;
   
    a{
      display:block;
      img{
      max-height:100%;
      height:80px;
      margin-bottom:5px;
      max-width:100%;
      vertical-align: middle;
      }
      .desc{
        white-space: nowrap;
        text-overflow:ellipsis;
        font-size:var(--font-size-s);
      }
    }
    
  }


@media (max-width: 768px) {

  /deep/.logo-item{
    width: 2.70rem;
    height: auto;

    margin:.12rem;
    text-align:center;
    vertical-align: top;
    a{
      img{
      height:0.85rem;
      vertical-align: middle;
      }
      .desc{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height:.8rem;
        padding:0 .1rem;
      }
    }
    
  }
}
</style>
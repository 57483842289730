<template>
  <div class="page">
    <div class="photo-container main-width-container">
      <div class="photo-title">{{ photoData.title }}</div>
      <div class="photo-info">
        <div class="create-time">{{ photoData.addTime }}</div>
        <div class="author">{{ photoData.authorName }}</div>
      </div>
      <div class="content">
        <div class="desc">
          {{ photoData.content }}
        </div>
        <div class="photo-container-active">
          <img :src="activeImgLink" alt="" />
        </div>
        <div class="albumn-container">
          <swiper ref="swiper" :options="swiperOption" class="photo-swiper" v-if="photoData.imageUrls">
            <!-- slides -->
            <swiper-slide v-for="(item, index) in photoData.imageUrls" :key="index">
              <div class="photo">
                <img :src="item" style="width:220px" />
              </div>
            </swiper-slide>
          </swiper>

          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetail } from '../api/competition'
export default {
  data () {
    return {
      photoData: [],
      activeImgLink: null,
      photoAlbumnData: [],
      baseUrl: this.$VUE_APP_PUBLIC,
      swiperOption: {
        autoplay: false,
        slidesPerView: 4,
        spaceBetween: 10,
        //   scrollbar: {
        //   el: '.swiper-scrollbar',
        //   draggable: true
        // },
        centeredSlides: true,
        loop: true,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChange: this.initCurrentPhoto,

        }
      }
    }
  },
  components: {

  },
  created () {
    this.getPhotoList()
  },
  methods: {
    initCurrentPhoto () {
      const realIndex = this.$refs.swiper.swiper.realIndex
      this.activeImgLink = this.photoData.imageUrls[realIndex]
    },
    //根据ID获取数据
    async getPhotoList () {
      const res = await getDetail(this.$route.params.id)
      this.photoData = res.data
      this.$nextTick(() => {
        this.initCurrentPhoto()
      })
    }
  }
}
</script>


<style scoped lang="less">
.page {
  background-image: url('../assets/images/bg-common.jpg');
  background-position: center top;
  background-size: cover;
  margin-bottom: -160px;
  padding-bottom: 300px;

  .photo-container {
    margin-top: 80px;
    background: #fff;
    border-radius: 20px;
    padding: 50px 50px 50px;

    .content {
      width: 1056px;
      margin: 0 auto;
      .desc {
        font-size: 16px;
        font-family: Microsoft YaHei;
        letter-spacing: 0;
        text-align: justify;
        line-height: 26px;
        margin-bottom: 20px;
      }
      .photo-container-active {
        height: 593px;
        img {
          height: 100%;
          display: block;
          max-width: 100%;
          max-height: 100%;
          margin: 0 auto;
        }
      }
      .albumn-container {
        position: relative;
        margin-top: 12px;
        width: 934px;
        margin: 12px auto;
        .swiper-button-next,
        .swiper-button-prev {
          height: 100%;
          margin: 0;
          top: 0;
          background-color: #deeaf7;
          width: 40px;
          border: 2px solid #c7ddf2;
          border-radius: 4px;
          background-size: 18px 40px;
        }
        .swiper-button-next {
          right: -55px;
        }
        .swiper-button-prev {
          left: -55px;
        }
        .swiper-scrollbar {
          margin-top: 10px;
          height: 9px;
          background-color: #eee;
          /deep/.swiper-scrollbar-drag {
            background-color: #1d81d4;
          }
        }
      }
      .swiper-slide {
        cursor: pointer;
        border: 3px solid #eee;
        box-sizing: border-box;
        .photo {
          aspect-ratio: 16/9;
        }
        img {
          max-width: 100%;
          margin: 0 auto;
          height: 100%;
          display: block;
        }
        &.swiper-slide-active {
          border-color: #1d81d4;
        }
      }
    }

    .photo-title {
      font-size: 24px;
    line-height: 1.8;
    color: #303133;
    padding-bottom: 20px;
    font-weight: 700;
    text-align: center;
    }
    .photo-info {
      text-align: center;
      color: #999;
      margin-bottom: 50px;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;

      div {
        display: inline-block;
        margin: 0 10px;
        letter-spacing: 1px;
        font-size: 14px;
        font-family: PingFangSC-Regular;
        color: #606266;
      }
    }
  }
}

@media (max-width: 768px) {
  .page {
    margin-bottom: -3rem;
    padding-bottom: 4.2rem;

    .photo-container {
      margin-top: 0;
      border-radius: 0;
      padding: 0.5rem 0;
      background: transparent;
      .photo-title {
        text-align: center;
        font-size: var(--font-size-xl-m);
        margin: 0 0 0.2rem;
      }

      .photo-info {
        text-align: center;
        color: #999;
        margin-bottom: 0.5rem;
        padding-bottom: 0.3rem;

        div {
          margin: 0.1rem 0.1rem;
          letter-spacing: 0.02rem;
        }
      }
      .content {
        width: 100%;
        margin: 0 auto;
        .desc {
          margin-bottom: 0.3rem;
          font-size: 0.32rem;
          line-height: 1.5;
          padding: 0;
          text-align: left;
          color: #333;

        }

        .photo-container-active {
          height: auto;
          width: 100%;
          aspect-ratio: 16 / 9;
          img {
          }
        }
        .albumn-container {
          margin-top: 0.2rem;
          width: 100%;
          margin: 12px auto;

          .swiper-button-next,
          .swiper-button-prev {
            display: none;
          }
        }
        .swiper-slide {
          cursor: pointer;
          border: 3px solid #eee;
          box-sizing: border-box;
          img {
            max-width: 100%;
            margin: 0 auto;
            height: 100%;
            display: block;
          }
        }
      }
    }
  }
}
</style>
/* 【参与大赛部分数据】 */

// 基层赛事数据
// 公示数据文件链接，可以没有？
// 注意！！陕西：shan3xi;山西：shan1xi
export const provinceData = {
  "status":200,
  "data":[
    {
      "id": 1,
      "province":"jilin",
      "name":"第三十二届吉林青少年科技创新大赛决赛",
      "date":"2022年4月15日~16日",
      "location":"吉林省长春市",
      "attachment":{
        "name":"第三十二届吉林青少年科技创新大赛获奖名单公示",
        "link":"www.baidu.com"
      }
    },
    {
      "id": 2,
      "province":"liaoning",
      "name":"第三十二届辽宁青少年科技创新大赛决赛",
      "date":"2022年2月1日~3日",
      "location":"辽宁省沈阳市",
      "attachment":{
        "name":"第三十二届辽宁青少年科技创新大赛获奖名单公示",
        "link":"www.baidu.com"
      }
    },
    {
      "id": 3,
      "province":"neimenggu",
      "name":"第三十二届内蒙古马术比赛",
      "date":"2022年5月1日",
      "location":"内蒙古自治区包头市",
      "attachment":{
        "name":"第三十二届内蒙青少年科技创新大赛获奖名单公示",
        "link":"www.baidu.com"
      }
    },
    {
      "id": 4,
      "province":"qinghai",
      "name":"环青海湖智力竞赛",
      "date":"2022年8月",
      "location":"青海省西宁市"
    },
    {
      "id": 6,
      "province":"tianjin",
      "name":"麻花相声大赛",
      "date":"2022年4月15日~16日",
      "location":"天津市"
    },
    {
      "id": 6,
      "province":"hebei",
      "name":"保定驴肉火烧传承大赛",
      "date":"2022年4月15日~16日",
      "location":"河北省保定市"
    },
    {
      "id": 7,
      "province":"shan1xi",
      "name":"老陈醋酿造大赛决赛",
      "date":"2022年4月15日~16日",
      "location":"陕西省大同市"
    },
    {
      "id": 8,
      "province":"shanghai",
      "name":"第三十二届开洋葱油面创新大赛决赛",
      "date":"2022年4月15日~16日",
      "location":"上海市"
    },
    {
      "id": 9,
      "province":"guangdong",
      "name":"第三十二届大湾区青少年科技创新大赛决赛",
      "date":"2022年7月1日~10日",
      "location":"广东省深圳市"
    },
  ]
}

// 赞助商页，获取到的获奖单位（按年份）,
export const sponsorByYearData = {
  "status":200,
  "data":[
    {
      year:2015,
      sponsor:[
        {
          "id": 1,
          "desc":"茅以升科学技术奖",
          "src":"/testImages/logo/1.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 2,
          "desc":"“国科大”科技创新奖",
          "src":"/testImages/logo/2.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 3,
          "desc":"《中国科技教育》杂志专项奖",
          "src":"/testImages/logo/3.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 4,
          "desc":"中鸣科学奖",
          "src":"/testImages/logo/4.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 5,
          "desc":"高士其科普奖",
          "src":"/testImages/logo/5.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 6,
          "desc":"杰出STEM项目奖",
          "src":"/testImages/logo/6.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 7,
          "desc":"《知识就是力量》杂志（全国）专项奖",
          "src":"/testImages/logo/7.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 8,
          "desc":"安恒信息专项奖",
          "src":"/testImages/logo/8.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 9,
          "desc":"第二页奖项1",
          "src":"/testImages/logo/1.jpg",
          "link":"http://www.google.com",
        }
      ]
    },
    {
      year:2017,
      sponsor:[
        {
          "id": 1,
          "desc":"某奖项2017",
          "src":"/testImages/logo/2.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 2,
          "desc":"茅以升科学技术奖2017",
          "src":"/testImages/logo/3.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 3,
          "desc":"茅以升科学技术奖2017",
          "src":"/testImages/logo/4.jpg",
          "link":"http://www.baidu.com",
        }
      ]
    },
    {
      year:2018,
      sponsor:[
        {
          "id": 1,
          "desc":"某奖项2018",
          "src":"/testImages/logo/2.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 2,
          "desc":"茅以升科学技术奖2018",
          "src":"/testImages/logo/3.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 3,
          "desc":"茅以升科学技术奖2018",
          "src":"/testImages/logo/4.jpg",
          "link":"http://www.baidu.com",
        }
      ]
    },
    {
      year:2019,
      sponsor:[
        {
          "id": 1,
          "desc":"某奖项2019",
          "src":"/testImages/logo/2.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 2,
          "desc":"茅以升科学技术奖2019",
          "src":"/testImages/logo/3.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 3,
          "desc":"茅以升科学技术奖2019",
          "src":"/testImages/logo/4.jpg",
          "link":"http://www.baidu.com",
        }
      ]
    },
    {
      year:2020,
      sponsor:[
        {
          "id": 4,
          "desc":"某奖项2020",
          "src":"/testImages/logo/2.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 5,
          "desc":"茅以升科学技术奖2020",
          "src":"/testImages/logo/3.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 6,
          "desc":"茅以升科学技术奖2020",
          "src":"/testImages/logo/4.jpg",
          "link":"http://www.baidu.com",
        }
      ]
    },
    {
      year:2021,
      sponsor:[
        {
          "id": 7,
          "desc":"某奖项2021",
          "src":"/testImages/logo/2.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 8,
          "desc":"茅以升科学技术奖某奖项2021",
          "src":"/testImages/logo/3.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id":9,
          "desc":"茅以升科学技术奖某奖项2021",
          "src":"/testImages/logo/4.jpg",
          "link":"http://www.baidu.com",
        }
      ]
    },
    {
      year:2022,
      sponsor:[
        {
          "id": 10,
          "desc":"某奖项2022",
          "src":"/testImages/logo/2.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id":11,
          "desc":"茅以升科学技术奖2022",
          "src":"/testImages/logo/3.jpg",
          "link":"http://www.baidu.com",
        },
        {
          "id": 12,
          "desc":"茅以升科学技术奖2022",
          "src":"/testImages/logo/4.jpg",
          "link":"http://www.baidu.com",
        }
      ]
    },
    
    
  ]
}

/* 颁奖现场  前端可切换年份展示，切换时向后台请求当年数据*/
export const bjxcData = {
  "status":200,
  "year":2021,
  "count":60,
  "data":[
    {
      "id": 1,
      "src":"/testImages/placeholder-16-9-1.jpg",
      "title": '闭幕式暨颁奖典礼',
      "link":'http://www.baidu.com',
      "createTime":'2022-07-15',
      "type": 'video'
    },
    {
      "id": 2,
      "src":"/testImages/placeholder-16-9-2.jpg",
      "title": '中国科技主席与海峡两岸中国科技主席与海峡两岸中国科技主席与海峡两岸',
      "link":'www.baidu.com',
      "createTime":'2022-07-10',
      "type": 'photo'
    },
    {
      "id": 3,
      "src":"/testImages/placeholder-16-9-3.jpg",
      "title": '中国科技主席与海峡两岸中国科技主席与海峡两岸',
      "link":'www.baidu.com',
      "createTime":'2022-06-29',
      "type": 'photo'
    },
    {
      "id": 4,
      "src":"/testImages/placeholder-16-9-4.jpg",
      "title": '朱瑾《探索太阳系小天体》',
      "link":'www.baidu.com',
      "createTime":'2022-06-15',
      "type": 'photo'
    },
    {
      "id": 5,
      "src":"/testImages/placeholder-16-9-5.jpg",
      "title": '我的创新故事：什么是科学素养',
      "link":'www.baidu.com',
      "createTime":'2022-05-15',
      "type": 'photo'
    },
    {
      "id": 6,
      "src":"/testImages/placeholder-16-9-6.jpg",
      "title": '我的创新故事：什么是科学素养',
      "link":'www.baidu.com',
      "createTime":'2022-05-15',
      "type": 'photo'
    },
    {
      "id": 7,
      "src":"/testImages/placeholder-16-9-7.jpg",
      "title": '我的创新故事：什么是科学素养',
      "link":'www.baidu.com',
      "createTime":'2022-05-15',
      "type": 'photo'
    },
    {
      "id": 8,
      "src":"/testImages/placeholder-16-9-8.jpg",
      "title": '我的创新故事：什么是科学素养',
      "link":'www.baidu.com',
      "createTime":'2022-05-15',
      "type": 'photo'
    },
    {
      "id": 9,
      "src":"/testImages/placeholder-16-9-5.jpg",
      "title": '我的创新故事：什么是科学素养',
      "link":'www.baidu.com',
      "createTime":'2022-05-15',
      "type": 'photo'
    },
    {
      "id": 10,
      "src":"/testImages/placeholder-16-9-6.jpg",
      "title": '我的创新故事：什么是科学素养',
      "link":'www.baidu.com',
      "createTime":'2022-05-15',
      "type": 'photo'
    },
    {
      "id": 11,
      "src":"/testImages/placeholder-16-9-7.jpg",
      "title": '我的创新故事：什么是科学素养',
      "link":'www.baidu.com',
      "createTime":'2022-05-15',
      "type": 'photo'
    },
    {
      "id": 12,
      "src":"/testImages/placeholder-16-9-8.jpg",
      "title": '我的创新故事：什么是科学素养',
      "link":'www.baidu.com',
      "createTime":'2022-05-15',
      "type": 'photo'
    },
    {
      "id": 13,
      "src":"/testImages/placeholder-16-9-5.jpg",
      "title": '我的创新故事：什么是科学素养',
      "link":'www.baidu.com',
      "createTime":'2022-05-15',
      "type": 'photo'
    },
    {
      "id": 14,
      "src":"/testImages/placeholder-16-9-6.jpg",
      "title": '我的创新故事：什么是科学素养',
      "link":'www.baidu.com',
      "createTime":'2022-05-15',
      "type": 'photo'
    },
    {
      "id": 15,
      "src":"/testImages/placeholder-16-9-7.jpg",
      "title": '我的创新故事：什么是科学素养',
      "link":'www.baidu.com',
      "createTime":'2022-05-15',
      "type": 'photo'
    },
    {
      "id": 16,
      "src":"/testImages/placeholder-16-9-8.jpg",
      "title": '我的创新故事：什么是科学素养',
      "link":'www.baidu.com',
      "createTime":'2022-05-15',
      "type": 'photo'
    }
  ]
}


/* 赞助商页面，顶部的三个按钮弹出内容汇集 */
export const sponsorRuleData = {
  "status":200,
  "data":{
    "sjtj":{"title":"设奖条件","content":"<p>专项奖设奖机构包括但不限于：社会团体、基金会、科研院所、事业单位、企业等。设奖机构应具有良好的社会信誉和公众形象，具有健全的财务管理制度，在参与大赛活动过程中不进行任何商业活动。</p><p>◇设立奖金总额不少于5万元。基本奖励形式为奖金,&nbsp;除奖金外，奖励形式还可以是提供参加国际交流的资助、奖学金、实物奖励和参与各类实习机会等。</p><p>◇设奖机构需向组委会缴纳不少于2万元的专项奖管理费，用于资助颁奖嘉宾食宿、专项奖颁奖典礼组织保障、获奖信息在线展示等费用。高校、科研院所、基金会、学会等非盈利性机构及在2018年前连续三年及以上设奖的企业可协商减免。</p><p>◇专项奖评审工作由设奖机构选聘评委独立完成，并对所评奖项的公平公正负责。专项奖评审工作需符合大赛章程、规则及组委会评审和组织工作的规定，评审标准自定。设奖机构须在规定时间内向大赛组委会提供评审结果。</p><p>◇专项奖证书由设奖机构设计制作。证书上应体现“全国青少年科技创新大赛专项奖”、奖项名称、获奖者姓名、项目名称、学校/单位、辅导教师、颁发日期等信息（证书由设奖单位自行填写）,设奖单位盖章。</p><p>◇设奖机构须至少在竞赛举办之前15日告知组委会秘书处颁奖代表信息。</p><p>◇专项奖获奖证书、奖金、奖品由设奖机构发给获奖者，并提供有效支付（领取）记录复印件在组委会秘书处备案。</p>"},
    "sjqy":{"title":"设奖权益","content":"<p>1.在大赛官网公布奖项名称及设奖机构；</p><p>2.在大赛活动指南等印刷品上注明专项奖奖项名称、设奖机构名称/LOGO；</p><p>3.在大赛期间安排专项奖评审时间,提供参赛信息和资料。提供每个设奖机构不超过10个专项奖评委的工作证件及其在评审工作日的午餐或晚餐；</p><p>4.大赛期间举办专项奖颁奖典礼，设奖机构可有一位代表在专项奖颁奖典礼上颁奖，并由大赛组委会提供颁奖代表在大赛期间的食宿;</p><p>5.在大赛官网发布专项奖获奖名单、颁奖典礼图文资料等。</p><p>6.设奖机构如需要在大赛期间进行媒体宣传、设置展位、资助大赛期间相关教育活动等，需根据当届大赛规模和承办地情况协商确定。&nbsp;</p>"},
    "sjcx":{"title":"设奖程序","content":"<p>1.设奖机构在每年6月30日前以书面或电子邮件形式向大赛组委会秘书处提出设奖；</p><p>2.大赛组委会秘书处审核后，与设奖机构确认细则，签署协议书；同时，设奖机构在大赛网站上完成设奖注册；</p><p>3.签署协议书后的一个月内，设奖机构须交纳管理费。如无法按期汇款，需提前以书面形式向组委会秘书处提出延期申请，否则将视为放弃设奖。</p>"},
  }
}